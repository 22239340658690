import {
  getTrayAutomationAppDetail,
  INCOMING_WEBHOOK_APP_NAMES,
  IncomingWebhook,
  IncomingWebhookAppName,
  IncomingWebhookOptimistic,
  IncomingWebhookPrefix,
  SolutionTypeTag,
  TemplateSolutionInstance,
  TrayAutomationAppName,
  TrayAutomationAppNames,
  TrayPrefix,
  WithIncomingWebhookPrefix,
  withIncomingWebhookPrefix,
  WithTrayPrefix,
  withTrayPrefix,
} from '@process-street/subgrade/automation';
import { match, P } from 'ts-pattern';
import { Muid } from '../core';
import { NativeAutomation, NativeAutomationWithLink, TaskTemplate } from '../process';

/**
 * Top level automation instance type that could represent any of the supported automation types at runtime.
 * Use pattern matching and AutomationInstanceUtils for branching logic/presentation.
 * */
export type AutomationInstance = TrayOrNativeAutomationInstance | IncomingWebhook | IncomingWebhookOptimistic;

export type TrayOrNativeAutomationInstance = TemplateSolutionInstance | NativeAutomationWithLink;

export type AutomationAppName = TrayAutomationAppName | IncomingWebhookAppName | typeof NativeAutomation.APP_NAME;

export type AutomationAppType = TrayPrefix | IncomingWebhookPrefix | NativeAutomation.Prefix;

export function isAutomationAppName(name: unknown): name is AutomationAppName {
  return (
    typeof name === 'string' &&
    (TrayAutomationAppNames.includes(name as TrayAutomationAppName) ||
      INCOMING_WEBHOOK_APP_NAMES.includes(name as IncomingWebhookAppName) ||
      name === NativeAutomation.APP_NAME)
  );
}

export function stripAutomationAppNamePrefix<PrefixedName extends AutomationAppName>(automationAppName: PrefixedName) {
  return automationAppName.split(':')[1] as PrefixedName extends WithTrayPrefix<infer AppName>
    ? AppName
    : PrefixedName extends WithIncomingWebhookPrefix<infer AppName>
    ? AppName
    : never;
}

export function isTrayAutomationAppName(name: AutomationAppName): name is TrayAutomationAppName {
  const prefix: TrayPrefix = 'tray';
  return name.startsWith(`${prefix}:`);
}

export function isIncomingWebhookAppName(name: AutomationAppName): name is IncomingWebhookAppName {
  const prefix: IncomingWebhookPrefix = 'webhook';
  return name.startsWith(`${prefix}:`);
}

export function getPrefixedAutomationAppName<Instance extends AutomationInstance>(automationInstance: Instance) {
  return match(automationInstance)
    .when(isTemplateSolutionInstance, obj => withTrayPrefix(obj.automationApp) as TrayAutomationAppName)
    .when(isIncomingWebhookInstance, obj => withIncomingWebhookPrefix(obj.automationApp) as IncomingWebhookAppName)
    .when(isNativeAutomationWithLink, () => NativeAutomation.APP_NAME as AutomationAppName)
    .run();
}

export function getAutomationAppNameLabel(appName: AutomationAppName): string {
  return match(appName)
    .when(isTrayAutomationAppName, name => getTrayAutomationAppDetail(name).label)
    .otherwise(() => stripAutomationAppNamePrefix(appName));
}

export function isTemplateSolutionInstance(obj?: AutomationInstance): obj is TemplateSolutionInstance {
  return match(obj)
    .with({ solutionInstanceId: P.string }, () => true)
    .otherwise(() => false);
}

export function isNativeAutomationWithLink(obj?: AutomationInstance): obj is NativeAutomationWithLink {
  return match(obj)
    .with({ automation: P.not(P.nullish), link: P.not(P.nullish) }, () => true)
    .otherwise(() => false);
}

export function isNullableTemplateSolutionInstance(
  obj?: AutomationInstance,
): obj is TemplateSolutionInstance | undefined {
  return isTemplateSolutionInstance(obj) || obj === undefined;
}

export function isTrayOrNativeAutomationInstance(
  obj?: AutomationInstance,
): obj is TrayOrNativeAutomationInstance | undefined {
  return isNullableTemplateSolutionInstance(obj) || isNativeAutomationWithLink(obj);
}

export function isIncomingWebhookInstance(obj?: AutomationInstance): obj is IncomingWebhook {
  return match(obj)
    .with({ webhookType: P.string }, () => true)
    .with({ templateId: P.string, action: 'CreateChecklist' }, () => true)
    .with({ dataSetId: P.string, action: 'UpdateDataSet' }, () => true)
    .otherwise(() => false);
}

export function isAutomationInstance(obj: any): obj is AutomationInstance {
  return isTemplateSolutionInstance(obj) || isIncomingWebhookInstance(obj);
}

const isEnabled = (obj: AutomationInstance): boolean => {
  return match(obj)
    .when(isTemplateSolutionInstance, obj => obj.configured && obj.enabled)
    .when(isIncomingWebhookInstance, obj => obj.status === 'Active')
    .when(isNativeAutomationWithLink, obj => obj.automation.status === 'Active')
    .otherwise(() => false);
};

const getSolutionTypeTag = (obj: AutomationInstance): SolutionTypeTag | undefined => {
  return match(obj)
    .when(isTemplateSolutionInstance, obj => obj.solutionTypeTag)
    .when(isIncomingWebhookInstance, obj =>
      obj.action === 'CreateChecklist'
        ? SolutionTypeTag.CreateChecklistWhen
        : // Data Set webhooks
          SolutionTypeTag.WhenChecklistCompletedThen,
    )
    .when(isNativeAutomationWithLink, obj =>
      obj.automation.automationType === 'WorkflowPageAutomation'
        ? SolutionTypeTag.WhenTaskCheckedThen
        : // Data Set automations
          SolutionTypeTag.CreateChecklistWhen,
    )
    .otherwise(() => undefined);
};

const getLabel = (obj: AutomationInstance): string => {
  return (
    match(obj)
      .when(isTemplateSolutionInstance, obj => obj.description)
      .when(isIncomingWebhookInstance, obj => obj.name || obj.automationApp)
      .when(isNativeAutomationWithLink, obj =>
        match(obj.automation.automationType)
          .with('WorkflowPageAutomation', () => NativeAutomation.workflowPageAutomationDescription)
          .otherwise(() => '(untitled)'),
      )
      .otherwise(() => undefined) ?? '(untitled)'
  );
};

const getAutomationInstanceType = (obj: AutomationInstance): AutomationAppType => {
  return match<AutomationInstance, AutomationAppType>(obj)
    .when(isTemplateSolutionInstance, () => 'tray')
    .when(isIncomingWebhookInstance, () => 'webhook')
    .when(isNativeAutomationWithLink, () => 'native')
    .run();
};

const getInstanceId = (obj: AutomationInstance): Muid | undefined =>
  match(obj)
    .when(isNativeAutomationWithLink, i => i.automation.id)
    .when(isTemplateSolutionInstance, i => i.id)
    .when(isIncomingWebhookInstance, i => i.id)
    .otherwise(() => undefined);

const isEnabledTaskInstance = (obj: AutomationInstance, taskTemplate: TaskTemplate): boolean =>
  isEnabled(obj) &&
  ((isTemplateSolutionInstance(obj) && obj.taskTemplateGroupId === taskTemplate.group.id) ||
    (isNativeAutomationWithLink(obj) && obj.link.taskTemplateId === taskTemplate.id));

export const AutomationInstanceUtils = {
  isEnabled,
  isEnabledTaskInstance,
  getSolutionTypeTag,
  getLabel,
  getAutomationInstanceType,
  getInstanceId,
};
