import * as React from 'react';
import { RichTextEditor } from 'features/rich-text';
import { match } from 'ts-pattern';
import { TextAreaRichEditorHelper } from './textarea-rich-editor-helper';

type TextAreaRichEditorProps = {
  hasDefaultValue?: boolean;
  readOnly: boolean;
  editable: boolean;
  setValue: (value: string) => void;
  onFocus: () => void;
  onBlur: () => void;
  placeholder?: string;
  value?: string;
};

const toolbar = [['bold', 'italic', 'strikethrough'], ['blocks'], ['bullist', 'numlist']]
  .map(section => section.join(' '))
  .join(' ');

export const TextAreaRichEditor: React.FC<TextAreaRichEditorProps> = ({
  setValue,
  hasDefaultValue = false,
  readOnly,
  editable,
  onFocus,
  onBlur,
  value,
  placeholder,
}) => {
  const backgroundColor = match({ hasDefaultValue, readOnly })
    .with({ readOnly: true }, () => 'gray.200')
    .with({ hasDefaultValue: true }, () => 'yellow.100')
    .otherwise(() => undefined);

  const initialValue = React.useMemo(
    () => (value ? TextAreaRichEditorHelper.markdown2html(value) : undefined),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want it to be calculated once
    [],
  );

  const onChange = (content: string) => {
    const markdown = TextAreaRichEditorHelper.html2markdown(content);
    setValue(markdown);
  };

  return (
    <RichTextEditor
      editorWrapperProps={{
        w: 'full',
        px: 4,
        borderWidth: '1px',
        borderColor: 'gray.200',
        borderRadius: '4px',
        borderStyle: 'solid',
        backgroundColor,
        minH: '80px',
        _focusWithin: { borderColor: 'blue.500', boxShadow: '0 0 0 1px var(--ps-colors-blue-500)' },
      }}
      init={{
        placeholder,
        toolbar,
      }}
      editorProps={{
        initialValue,
        onEditorChange: onChange,
        onFocus,
        onBlur,
        disabled: !editable,
      }}
    />
  );
};
