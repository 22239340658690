import * as React from 'react';
import { TemplateType } from '@process-street/subgrade/process';
import { PageContentActorRef } from './page-content-machine';
import { useActor } from '@xstate/react';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { ContentFieldRecentlyMovedIndicator } from '../common/content-field-recently-moved-indicator';
import { CrossLinkCardSearch } from 'app/components/widgets/cross-link/search';
import { TemplateTypeProvider } from 'app/utils/template/template-type-context';
import { Muid } from '@process-street/subgrade/core';
import { CrossLinkCardDisplay } from 'app/components/widgets/cross-link/display';
import { useMatch } from '@process-street/adapters/navigation';

export interface PageContentProps {
  actor: PageContentActorRef;
  isFirst?: boolean;
  isLast?: boolean;
}

export const PageContent: React.FC<React.PropsWithChildren<PageContentProps>> = ({
  actor,
  isFirst = false,
  isLast = false,
}) => {
  const [current, send] = useActor(actor);
  const { widget, recentlyMovedFrom, isReadOnly } = current.context;
  const isEditPageV2 = useMatch('pageV2');

  const templateTypeLookup = isEditPageV2 ? TemplateType.Playbook : TemplateType.Page;
  // Don't show empty page widget in view mode
  if (isReadOnly && !widget.templateId) return null;

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer alignItems="flex-start">
        {recentlyMovedFrom && <ContentFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
        <TemplateTypeProvider templateType={templateTypeLookup}>
          {widget.templateId ? (
            <CrossLinkCardDisplay templateId={widget.templateId} />
          ) : (
            <CrossLinkCardSearch
              onSelect={(templateId: Muid) => send({ type: 'CHANGE', templateId })}
              onFocus={() => send('FOCUS')}
              isReadOnly={isReadOnly}
            />
          )}
        </TemplateTypeProvider>
        {!isReadOnly && (
          <>
            <WidgetListItemDragIcon />
            <FormsWidgetMenu>
              <FormsWidgetMenuItems.Duplicate />
              <FormsWidgetMenuItems.MoveToStep widget={widget} />
              <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
              <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
              <FormsWidgetMenuItems.Delete />
            </FormsWidgetMenu>
          </>
        )}
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};
