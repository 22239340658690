import * as React from 'react';
import { useActor } from '@xstate/react';
import { TextFormFieldActor } from './text-form-field-machine';
import { Input, InputGroup, VStack } from 'components/design/next';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { FormFieldLabel } from '../common/form-field-label';
import { SettingsModalFields } from 'pages/forms/_id/edit/components/form-fields/common/settings/fields';
import { WidgetActorProvider } from '../../../../shared/widget-context';
import { SettingsModalHeader } from 'pages/forms/_id/edit/components/form-fields/common/settings/settings-modal-content';
import { textSettingsSchema } from './text-form-field-schema';
import { StringUtils } from '@process-street/subgrade/util';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { FormFieldRecentlyMovedIndicator } from '../common/form-field-recently-moved-indicator';
import { match, P } from 'ts-pattern';
import { TaskTemplate, TemplateType } from '@process-street/subgrade/process';
import { ViewModeInteractionWrapper } from '../../view-mode-interaction-wrapper/view-mode-interaction-wrapper';
import { AiTaskTemplateWidgetIndicatorV2 } from 'app/pages/templates/_id/components/ai-task-template-widget-indicator';

export type TextFormFieldWidgetProps = {
  isFirst: boolean;
  isLast: boolean;
  actor: TextFormFieldActor;
};

export const TextFormFieldWidget = React.memo(({ actor, isFirst, isLast }: TextFormFieldWidgetProps) => {
  const [state, send] = useActor(actor);
  const { widget, labelActor, recentlyMovedFrom, template, isReadOnly } = state.context;
  const ref = React.useRef<HTMLDivElement | null>(null);

  const placeholder = match(widget)
    .with({ config: { defaultValue: P.when(Boolean) } }, ({ config: { defaultValue } }) => defaultValue)
    .with({ config: { placeholder: P.when(Boolean) } }, ({ config: { placeholder } }) => placeholder)
    .otherwise(() => 'Short text will be typed here');

  const isWorkflow = template.templateType === TemplateType.Playbook;

  const handleSelectTaskTemplate = (taskTemplate: TaskTemplate) => send({ type: 'SELECT_TASK_TEMPLATE', taskTemplate });

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer>
        <VStack alignItems="flex-start" flex="1">
          {recentlyMovedFrom && <FormFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
          {labelActor && <FormFieldLabel isReadOnly={isReadOnly} actor={labelActor} />}

          <ViewModeInteractionWrapper templateId={template.id}>
            <InputGroup
              w="full"
              ref={node => {
                ref.current = node;
                if (node && !state.context.inputNode) {
                  send({ type: 'SET_NODE', node });
                }
              }}
            >
              <Input
                id={`form-field-widget-${widget.id}`}
                w="full"
                placeholder={placeholder}
                isDisabled
                borderColor="gray.200"
                _disabled={{ bgColor: 'gray.50', opacity: 1 }}
              />

              {!isReadOnly && (
                <>
                  <WidgetListItemDragIcon />
                  <FormsWidgetMenu>
                    <FormsWidgetMenuItems.Required widget={widget} />
                    <FormsWidgetMenuItems.Settings widget={widget} schema={textSettingsSchema}>
                      <SettingsModalHeader>
                        {StringUtils.getNonEmpty(widget?.label, 'Untitled text field')}
                      </SettingsModalHeader>
                      <SettingsModalFields.MinMax<typeof textSettingsSchema>
                        minFieldName="constraints.min"
                        maxFieldName="constraints.max"
                        unit="character limit"
                      >
                        <SettingsModalFields.MinMaxTo />
                      </SettingsModalFields.MinMax>
                      <SettingsModalFields.Placeholder />
                      {isWorkflow && (
                        <SettingsModalFields.DefaultValue templateRevisionId={widget.templateRevision.id} />
                      )}
                    </FormsWidgetMenuItems.Settings>
                    <FormsWidgetMenuItems.ConditionalLogic widget={widget} />

                    <FormsWidgetMenuItems.Divider />

                    <FormsWidgetMenuItems.Duplicate />
                    <FormsWidgetMenuItems.MoveToStep widget={widget} />
                    <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
                    <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
                    <FormsWidgetMenuItems.Delete />
                  </FormsWidgetMenu>
                </>
              )}
            </InputGroup>
          </ViewModeInteractionWrapper>
          {isWorkflow && (
            <AiTaskTemplateWidgetIndicatorV2 widget={widget} handleOnSelectTaskTemplate={handleSelectTaskTemplate} />
          )}
        </VStack>
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
});
