import { MergeTagsConstants } from '@process-street/subgrade/form';
import { escapeHtml, nl2br, unescapeHtml } from '@process-street/subgrade/util';
import { getAllTextNodesAsSpans } from './get-all-text-nodes-as-spans';
import { replaceInTextSpansInPlace } from './replace-in-text-spans-in-place';
import { generatePatternByKey } from './generate-pattern-by-key';
import { applyFilter } from './apply-filter';
import { ResolvedTag, ResolvedTagFormat } from '@process-street/subgrade/merge-tags';
import { divToHtmlString } from './div-to-html-string';
import { EMPTY_MERGE_TAG_CLASS, EMPTY_MERGE_TAG_STYLES } from './inject-merge-tag-labels';
import { TextAreaRichEditorHelper } from 'pages/runs/_id/components/checklist-textarea-form-field-widget/textarea-rich-editor-helper';

const HTML_TAG_DELIMITER = '/==HTML==/';

/**
 * Replaces merge tags with resolved values.
 */
export function replaceResolvedTagsWithHtmlValues(
  resolvedTags: Array<ResolvedTag>,
  content: string,
  _options: {} = {},
): string {
  let value = content;

  const missingKeys: { key: string; label: string }[] = [];

  resolvedTags.forEach(tag => {
    const format = tag.meta?.format;
    const isMarkdown = format === ResolvedTagFormat.Markdown;
    const isHtml = format === ResolvedTagFormat.Html;
    const replacement =
      tag.replacement && isMarkdown ? TextAreaRichEditorHelper.markdown2html(tag.replacement) : tag.replacement;

    const pattern = generatePatternByKey(MergeTagsConstants.Mode.HTML, tag.key);
    value = value.replace(pattern, (__match, __key, filter, param) => {
      // When we're in HTML mode, we need to unescape the param because it will have been escaped
      // They will be re-escaped after the filter is applied (if they were used in the replacement)
      const unescapedParam = unescapeHtml(param);
      const filteredReplacement = applyFilter({
        filter,
        param: unescapedParam,
        key: tag.key,
        value: replacement ?? '',
      });

      if (isMarkdown) {
        return filteredReplacement;
      } else if (isHtml) {
        // Closing paragraphs with special marker, so we'll be able to remove empty paragraphs around HTML
        return `${HTML_TAG_DELIMITER}</p>${filteredReplacement}<p>${HTML_TAG_DELIMITER}`;
      } else {
        return nl2br(escapeHtml(filteredReplacement));
      }
    });

    if (!replacement) {
      missingKeys.push({ key: tag.key, label: tag.default });
    }
  });

  // Create a jQuery element for parsing
  const el = document.createElement('div');
  el.innerHTML = value
    .replaceAll(`<p>${HTML_TAG_DELIMITER}</p>`, '') // remove empty paragraphs around HTML
    .replaceAll(HTML_TAG_DELIMITER, ''); // remove remaining special sequences

  const textSpans = getAllTextNodesAsSpans(el);
  missingKeys.forEach(missingKeyTag => {
    const missingHtmlValue = `<span class='${EMPTY_MERGE_TAG_CLASS}' style='${EMPTY_MERGE_TAG_STYLES}' title='Variable value is missing.'>${escapeHtml(
      missingKeyTag.label,
    )}</span>`;

    replaceInTextSpansInPlace(textSpans, missingKeyTag.key, missingHtmlValue);
  });

  value = divToHtmlString(el);

  return value;
}
