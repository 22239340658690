import * as React from 'react';
import {
  Box,
  Divider,
  HStack,
  IconButton,
  MenuButton,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Tooltip,
} from 'components/design/next';
import { MergeTagsMenu } from 'app/features/merge-tags/components/merge-tags-menu';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { createMergeTag } from 'app/hooks/use-insert-merge-tag-callback';
import { escapeHtml } from 'markdown-it/lib/common/utils';
import { AiGenerateTextWidgetContentMenu } from 'app/features/widgets/components/ai/ai-generate-text-widget-content-menu';
import { TextWidget, WithTaskTemplate } from '@process-street/subgrade/process';
import { TinyMCEEditor } from 'app/features/rich-text';
import { FormatButton, getFormatButtonStyles } from './format-button';
import { TextAlignment } from './text-alignment';
import { TextStyle } from './text-style';
import { TextFormats } from './text-formats';
import { Icon } from 'app/components/design/next';
import { useCommandsStatus } from '../hooks/use-commands-status';
import { useToolbarDisclosure } from '../hooks/use-toolbar-disclosure';
import { useToolbarAnchorPosition } from '../hooks/use-toolbar-anchor-position';

export type TextWidgetToolbarProps = {
  editor?: TinyMCEEditor;
  widget: WithTaskTemplate<TextWidget>;
};

export const TextWidgetToolbar = ({ editor, widget }: TextWidgetToolbarProps) => {
  const popoverContentRef = React.useRef<HTMLDivElement | null>(null);
  const toolbarDisclosure = useToolbarDisclosure({ editor, popoverContentRef });
  const templateRevisionId = widget.header.taskTemplate.templateRevision?.id;
  const anchorRect = useToolbarAnchorPosition({ editor });
  const { disabledCommands, activeCommands } = useCommandsStatus({ editor });

  return (
    <Popover {...toolbarDisclosure} closeOnBlur={false} autoFocus={false} placement="top">
      {anchorRect && toolbarDisclosure.isOpen && (
        <PopoverAnchor>
          <Box
            visibility="hidden"
            position="fixed"
            style={{
              top: anchorRect.top,
              left: anchorRect.left,
              width: anchorRect.width,
              height: anchorRect.height,
            }}
          />
        </PopoverAnchor>
      )}

      <PopoverContent mt={-10} p={0} w="max-content" ref={popoverContentRef} bgColor="gray.700">
        <PopoverBody p={0}>
          <HStack spacing={0.5}>
            <FormatButton
              editor={editor}
              label="Undo"
              icon="rotate-left"
              command="Undo"
              isActive={activeCommands['Undo']}
              isDisabled={disabledCommands['Undo']}
            />
            <FormatButton
              editor={editor}
              label="Redo"
              icon="rotate-right"
              command="Redo"
              isActive={activeCommands['Redo']}
              isDisabled={disabledCommands['Redo']}
            />

            <Divider h="8" w="1px" bgColor="gray.600" orientation="vertical" />

            <TextFormats editor={editor} activeCommands={activeCommands} />

            <FormatButton
              editor={editor}
              label="Bold"
              icon="bold"
              command="Bold"
              isActive={activeCommands['Bold']}
              isDisabled={disabledCommands['Bold']}
            />

            <FormatButton
              editor={editor}
              label="Italic"
              icon="italic"
              command="Italic"
              isActive={activeCommands['Italic']}
              isDisabled={disabledCommands['Italic']}
            />

            <FormatButton
              editor={editor}
              label="Underline"
              icon="underline"
              command="Underline"
              isActive={activeCommands['Underline']}
              isDisabled={disabledCommands['Underline']}
            />

            <FormatButton
              editor={editor}
              label="Strikethrough"
              icon="strikethrough"
              command="Strikethrough"
              isActive={activeCommands['Strikethrough']}
              isDisabled={disabledCommands['Strikethrough']}
            />

            <TextStyle editor={editor} />

            <Divider h="8" w="1px" bgColor="gray.600" orientation="vertical" />

            <FormatButton
              editor={editor}
              label="Unordered List"
              command="insertUnorderedList"
              icon="list"
              isActive={activeCommands['insertUnorderedList']}
            />

            <FormatButton
              editor={editor}
              label="Ordered List"
              command="insertOrderedList"
              icon="list-ol"
              isActive={activeCommands['insertOrderedList']}
            />

            <TextAlignment editor={editor} activeCommands={activeCommands} />

            <FormatButton
              editor={editor}
              label="Horizontal Line"
              command="insertHorizontalRule"
              icon="horizontal-rule"
              isActive={activeCommands['insertHorizontalRule']}
            />

            <Divider h="8" w="1px" bgColor="gray.600" orientation="vertical" />

            <FormatButton
              editor={editor}
              label="Link"
              icon="link"
              command="mceLink"
              isActive={activeCommands['mceLink']}
              isDisabled={disabledCommands['mceLink']}
            />

            <MergeTagsMenu
              menuButton={
                <Tooltip label="Variables">
                  <MenuButton
                    as={IconButton}
                    {...getFormatButtonStyles()}
                    aria-label="Variables"
                    icon={<Icon icon="wand-magic-sparkles" size="3.5" />}
                  />
                </Tooltip>
              }
              mergeTagTarget={MergeTagsConstants.Target.RICH_CONTENT}
              templateRevisionId={templateRevisionId}
              onSelect={(key, _fieldId, fallback) => {
                editor?.insertContent(createMergeTag(escapeHtml(key), fallback));
              }}
            />

            <AiGenerateTextWidgetContentMenu
              editor={editor}
              widget={widget}
              menuButton={
                <Tooltip label="Generate content with AI">
                  <MenuButton
                    as={IconButton}
                    {...getFormatButtonStyles()}
                    aria-label="Generate Content with AI"
                    icon={<Icon icon="sparkles" size="3.5" />}
                  />
                </Tooltip>
              }
              onContentGenerated={w => {
                if ('content' in w) {
                  editor?.setContent((w as TextWidget).content ?? '', { format: 'html' });
                }
              }}
            />
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
