import * as React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';

type Context = {
  handleAddRow: () => void;
  isEditable: boolean;
};

export const [useTableContentContext, TableContentContext] = createUsableContext<Context>({
  hookName: 'useTableContentContext',
  providerName: 'TableContentContext.Provider',
});

export const TableContentContextProvider: React.FunctionComponent<React.PropsWithChildren<Context>> = ({
  children,
  handleAddRow,
  isEditable,
}) => {
  const value = React.useMemo(() => ({ handleAddRow, isEditable }), [handleAddRow, isEditable]);
  return <TableContentContext.Provider value={value}>{children}</TableContentContext.Provider>;
};
