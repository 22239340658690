import * as React from 'react';
import { TableFormFieldConfig } from '@process-street/subgrade/process/configs/table-form-field-config';
import { TableFieldValue } from '@process-street/subgrade/process/field-values/table-field-value';
import { TableContentUtils } from '../utils';
import isEmpty from 'lodash/isEmpty';

export type TableData = {
  headers: TableFormFieldConfig.ColumnDef[];
  rows: TableFieldValue.Row[];
};

export const useContentTableData = (content: string, isEditable: boolean) => {
  const [tableData, setTableData] = React.useState<TableData>({ headers: [], rows: [] });
  const dataLoaded = React.useRef(false);
  React.useEffect(() => {
    if (dataLoaded.current) {
      return;
    }
    if (!isEmpty(content)) {
      const { headers, rows } = TableContentUtils.generateTableDataFromHTML(content, isEditable);
      setTableData({ headers, rows });
      dataLoaded.current = true;
    }
  }, [content, isEditable]);

  return { tableData, setTableData };
};
