import { TemplateStatus } from '@process-street/subgrade/process';
import { Box, Button, Icon, MenuDivider, Tooltip, Text, useMenuContext } from 'components/design/next';
import { ArchiveTemplateButton } from './archive-template-button';
import { CopyShareLinkButton } from './copy-share-link-button';
import { DuplicateTemplateButton } from './duplicate-template-button';
import { ManageTemplateTagsButton } from './manage-template-tags-button';
import { MoveTemplateButton } from './move-template-button';
import { TemplateActivityButton } from './template-activity-button';
import { WorkflowRunsButton } from './workflow-runs-button';
import { useIsPrivateTemplate } from 'hooks/use-is-private-template';
import * as React from 'react';
import { AddToPublicLibraryButton } from './add-to-public-library-button';
import { SubscribeButton } from './subscribe-button';
import { RunMultipleButton, ScheduleWorkflowButton } from 'components/template-menu-buttons';
import { PrintButton } from './print-button';
import { WorkflowRunLinkButton } from './workflow-run-link-button';
import { ExportCsvButton } from './export-csv-button';
import { TemplateMenuItem, useTemplateMenuContext } from '.';
import { useFeatureFlags } from 'features/feature-flags';
import { useGetOrganizationQuery } from 'features/organization/query-builder';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { match } from 'ts-pattern';
import { useGetCanShareLinks } from 'utils/plans/get-can-share-links';
import { SyncChangesButton } from 'features/template/components/template-menu/sync-changes-button';
import { ExportConditionalLogicButton } from './export-conditional-logic-button';
import { useFeatureFlag } from 'features/feature-flags';
import { CustomNotificationsButton } from './custom-notifications-button';
import { TemplateShareButton } from 'app/directives/template-share/template-share-button';
import { TemplateCommentsOverviewDrawer } from 'app/features/comments/components/template/comments-overview-drawer';
import { DiscardTemplateButton } from './discard-template-button';

export interface WorkflowEditMenuProps {
  children: React.ReactNode;
}

export const WorkflowEditMenu = ({ children }: WorkflowEditMenuProps) => {
  const { templateId } = useTemplateMenuContext();
  const workflowIsPrivate = useIsPrivateTemplate(templateId);
  const menuContext = useMenuContext();
  const isExportConditionalLogicEnabled = useFeatureFlag('exportConditionalLogic');

  const canShareLinks = useGetCanShareLinks();

  const { publishToPublicTemplateLibrary: isPublicPublishFeatureEnabled } = useFeatureFlags(
    'publishToPublicTemplateLibrary',
  );

  const userInfoQuery = useGetCurrentUserInfoQuery();
  const organizationId = userInfoQuery.data?.organizationMembership.organization.id;
  const organizationQuery = useGetOrganizationQuery({ organizationId }, { enabled: userInfoQuery.isSuccess });
  const isEnhancedFileSecurityEnabled = organizationQuery.data?.enhancedFileSecurity;

  // Disable publish to public template library for users with enhanced file security enabled
  const isPublicPublishEnabled = isPublicPublishFeatureEnabled && !isEnhancedFileSecurityEnabled;

  const isPublicPublishDisabled = !isPublicPublishEnabled || !canShareLinks;

  const disabledTooltipText = match({ isPublicPublishEnabled, canShareLinks })
    .with({ isPublicPublishEnabled: false }, () => 'This feature is disabled by Enhanced File Security.')
    .with(
      { canShareLinks: false },
      () =>
        'Add to Public Library will be enabled after a 7-day period on any paid plan. For expedited access, please contact our support team.',
    )
    .otherwise(() => '');

  return (
    <>
      {children}

      {!workflowIsPrivate && (
        <TemplateShareButton templateId={templateId} isDisabled={!canShareLinks}>
          <TemplateMenuItem
            aria-label="share workflow"
            icon={<Icon icon="share" variant="far" size="4" color="gray.600" />}
            closeOnSelect
          >
            Share
          </TemplateMenuItem>
        </TemplateShareButton>
      )}

      <CustomNotificationsButton templateId={templateId}>
        <TemplateMenuItem icon={<Icon icon="bell" size="4" color="gray.500" />} closeOnSelect>
          Custom notifications
        </TemplateMenuItem>
      </CustomNotificationsButton>

      <TemplateCommentsOverviewDrawer templateId={templateId}>
        <TemplateMenuItem icon={<Icon icon="comment" variant="far" size="4" color="gray.500" />} closeOnSelect>
          Run comments
        </TemplateMenuItem>
      </TemplateCommentsOverviewDrawer>

      <WorkflowRunsButton>
        <TemplateMenuItem icon={<Icon icon="th-list" size="4" variant="far" color="gray.500" />}>
          View workflow runs
        </TemplateMenuItem>
      </WorkflowRunsButton>

      <MenuDivider />

      {!workflowIsPrivate && (
        <CopyShareLinkButton templateId={templateId}>
          {({ copiedValue }) => (
            <TemplateMenuItem
              icon={
                <Icon
                  icon={copiedValue ? 'check' : 'link'}
                  size="4"
                  variant="far"
                  color={copiedValue ? 'green.500' : 'gray.500'}
                />
              }
            >
              Copy share link
            </TemplateMenuItem>
          )}
        </CopyShareLinkButton>
      )}

      <WorkflowRunLinkButton templateId={templateId}>
        <TemplateMenuItem icon={<Icon icon="play" size="4" variant="far" color="gray.500" />}>
          Workflow run link
        </TemplateMenuItem>
      </WorkflowRunLinkButton>

      <RunMultipleButton templateId={templateId}>
        <TemplateMenuItem icon={<Icon icon="forward" size="4" variant="far" color="gray.500" />}>
          Run multiple
        </TemplateMenuItem>
      </RunMultipleButton>

      <ScheduleWorkflowButton templateId={templateId}>
        <TemplateMenuItem icon={<Icon icon="clock" size="4" variant="far" color="gray.500" />}>
          Schedule workflow
        </TemplateMenuItem>
      </ScheduleWorkflowButton>

      <MenuDivider />

      <TemplateActivityButton>
        <TemplateMenuItem icon={<Icon icon="history" size="4" variant="far" color="gray.500" />}>
          Activity
        </TemplateMenuItem>
      </TemplateActivityButton>

      <SubscribeButton>
        {({ isSubscribed }) => (
          <TemplateMenuItem
            as={Button}
            variant="unstyled"
            borderRadius="0"
            whiteSpace="normal"
            icon={<Icon icon="eye" size="4" variant="far" color="gray.500" />}
            rightIcon={isSubscribed ? <Icon icon="check" size="4" variant="far" color="green.500" /> : undefined}
          >
            {isSubscribed ? 'Subscribed' : 'Subscribe'}
          </TemplateMenuItem>
        )}
      </SubscribeButton>

      <ManageTemplateTagsButton>
        <TemplateMenuItem icon={<Icon icon="tag" color="gray.500" variant="far" size="4" />}>
          Manage tags
        </TemplateMenuItem>
      </ManageTemplateTagsButton>

      {isPublicPublishFeatureEnabled ? (
        <Box position="relative">
          <Tooltip isDisabled={!isPublicPublishDisabled} label={<Text>{disabledTooltipText}</Text>}>
            {/* wrap children in Box because popover hover doesn't work with disabled buttons */}
            <Box>
              <AddToPublicLibraryButton>
                {({ isPublic }) => (
                  <TemplateMenuItem
                    isDisabled={isPublicPublishDisabled}
                    isFocusable={!isPublicPublishDisabled}
                    as={Button}
                    variant="unstyled"
                    borderRadius="0"
                    whiteSpace="normal"
                    icon={<Icon icon="share-alt" size="4" variant="far" color="gray.500" />}
                  >
                    {isPublic ? 'Public Library info' : 'Add to Public Library'}
                  </TemplateMenuItem>
                )}
              </AddToPublicLibraryButton>
            </Box>
          </Tooltip>
        </Box>
      ) : null}

      <MenuDivider />

      <MoveTemplateButton onMoved={() => menuContext.onClose()}>
        <TemplateMenuItem icon={<Icon icon="arrow-right" size="4" variant="far" color="gray.500" />}>
          Move
        </TemplateMenuItem>
      </MoveTemplateButton>

      <DuplicateTemplateButton>
        <TemplateMenuItem icon={<Icon icon="copy" size="4" variant="far" color="gray.500" />}>
          Duplicate
        </TemplateMenuItem>
      </DuplicateTemplateButton>

      <SyncChangesButton>
        <TemplateMenuItem icon={<Icon icon="code-merge" size="4" variant="far" color="gray.500" />}>
          Sync Changes
        </TemplateMenuItem>
      </SyncChangesButton>

      <ExportCsvButton>
        <TemplateMenuItem icon={<Icon icon="arrow-to-bottom" size="4" variant="far" color="gray.500" />}>
          Export
        </TemplateMenuItem>
      </ExportCsvButton>

      <PrintButton>
        <TemplateMenuItem icon={<Icon icon="print" size="4" variant="far" color="gray.500" />}>Print</TemplateMenuItem>
      </PrintButton>

      {isExportConditionalLogicEnabled && (
        <ExportConditionalLogicButton>
          <TemplateMenuItem icon={<Icon icon="print" size="4" variant="far" color="gray.500" />}>
            Print Conditional Logic Rules
          </TemplateMenuItem>
        </ExportConditionalLogicButton>
      )}

      <ArchiveTemplateButton onArchived={() => menuContext.onClose()}>
        {({ templateStatus, isMutating }) => {
          const icon = isMutating ? (
            <Icon icon="spinner-third" animation="spin" size="4" variant="far" color="gray.500" />
          ) : (
            <Icon icon="archive" size="4" variant="far" color="gray.500" />
          );
          return (
            <TemplateMenuItem isDisabled={isMutating} icon={icon} isFocusable={!isMutating}>
              {templateStatus === TemplateStatus.Active ? 'Archive' : 'Unarchive'}
            </TemplateMenuItem>
          );
        }}
      </ArchiveTemplateButton>

      <MenuDivider />

      <DiscardTemplateButton>
        <TemplateMenuItem
          aria-label="discard changes"
          color="red.500"
          icon={<Icon icon="trash-alt" color="red.500" size="4" />}
        >
          Discard Changes
        </TemplateMenuItem>
      </DiscardTemplateButton>
    </>
  );
};
