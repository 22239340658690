import * as React from 'react';
import { Template, TemplateRevision } from '@process-street/subgrade/process';
import { useInjector } from 'components/injection-provider';
import { ConditionalLogicModal } from 'features/conditional-logic/components/modal';
import { useDisclosure } from 'components/design/next';
import { CONDITIONAL_LOGIC_MODAL_EVENT } from 'features/conditional-logic/components/modal/conditional-logic-modal-event';
import { AppModalName } from 'app/app.constants';
import { useNgStateModalControls } from 'app/components/ng-state-modal-controls';

export interface ConditionalLogicModalProps {
  templateRevision: TemplateRevision;
}

export const ConditionalLogicModalWrapper: React.VFC<ConditionalLogicModalProps> = ({ templateRevision }) => {
  const { $rootScope } = useInjector('$rootScope');
  const { isOpen: isOpenFromNgState, onClose: onCloseFromNgState } = useNgStateModalControls(
    AppModalName.ConditionalLogic,
  );
  const isOpenFromNgStateRef = React.useRef(false);

  const disclosure = useDisclosure();

  const onClose = () => {
    disclosure.onClose();
    if (isOpenFromNgState) {
      onCloseFromNgState();
    }
  };

  React.useEffect(() => {
    const unsubs = [
      $rootScope.$on(CONDITIONAL_LOGIC_MODAL_EVENT.OPEN, () => {
        disclosure.onOpen();
      }),
      $rootScope.$on(CONDITIONAL_LOGIC_MODAL_EVENT.TOGGLE, () => {
        disclosure.onToggle();
      }),
      $rootScope.$on(CONDITIONAL_LOGIC_MODAL_EVENT.CLOSE, () => {
        disclosure.onClose();
      }),
    ];

    return () => {
      unsubs.forEach(unsub => unsub());
    };
  }, [$rootScope, disclosure]);

  React.useEffect(
    function openFromNgState() {
      if (isOpenFromNgState && !isOpenFromNgStateRef.current) {
        isOpenFromNgStateRef.current = true;
        disclosure.onOpen();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore disclosure
    [isOpenFromNgState],
  );

  return disclosure.isOpen ? (
    <ConditionalLogicModal
      template={templateRevision.template as Template}
      templateRevisionId={templateRevision.id}
      {...disclosure}
      onClose={onClose}
    />
  ) : null;
};
