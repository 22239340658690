import { InsertWidgetsSection } from 'app/pages/workflows/_id/edit-v2/components/insert-widgets-section/insert-widgets-section';
import { FocusBar } from './focus-bar';
import { PageName } from '../../edit/page/components';
import { WidgetsList } from 'app/pages/forms/_id/edit/components';

export const PagesEditor = {
  FocusBar,
  InsertWidgetsSection,
  Title: PageName,
  WidgetsList,
};
