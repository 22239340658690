import { MuidUtils } from '@process-street/subgrade/core';
import { TableFormFieldConfig } from '@process-street/subgrade/process/configs/table-form-field-config';
import { TableFieldValue } from '@process-street/subgrade/process/field-values/table-field-value';
import { TableData } from 'app/pages/forms/_id/edit/components/content/table-content-widget/hooks/use-content-table-data';

const handleRenameColumn = (headers: TableFormFieldConfig.ColumnDef[], columnId: string, newName: string) => {
  const newHeaders = headers.map(header => {
    if (header.id === columnId) {
      return { ...header, name: newName };
    }
    return header;
  });
  return newHeaders;
};

export type ColumnDirection = 'left' | 'right';

const handleInsertColumn = ({
  tableData,
  columnId,
  columnName = 'New Column',
  direction,
}: {
  tableData: TableData;
  columnId: string;
  columnName?: string;
  direction: ColumnDirection;
}) => {
  const columnIndex = tableData.headers.findIndex(header => header.id === columnId);
  if (columnIndex === -1) {
    throw new Error('Column not found');
  }
  const insertIndex = direction === 'left' ? columnIndex : columnIndex + 1;
  const newHeaderId = MuidUtils.randomMuid();
  const updatedHeaders: TableFormFieldConfig.ColumnDef[] = [
    ...tableData.headers.slice(0, insertIndex),
    { id: newHeaderId, name: columnName, columnType: 'Text' },
    ...tableData.headers.slice(insertIndex),
  ];

  const updatedRows = tableData.rows.map(row => {
    // Insert the new cell in the corresponding position in each row's cells
    const updatedCells: Record<string, { value: string | number | null }> = {};
    let count = 0;

    tableData.headers.forEach((header, idx) => {
      // Add existing cells to updatedCells
      updatedCells[header.id] = row.cells[header.id];

      // Insert the new cell at the right place
      if (idx === columnIndex && direction === 'left') {
        updatedCells[`${newHeaderId}`] = { value: null };
        count++;
      }

      if (count === 0 && idx === columnIndex && direction === 'right') {
        updatedCells[`${newHeaderId}`] = { value: null };
        count++;
      }
    });

    return {
      ...row,
      cells: updatedCells,
    };
  });

  return { headers: updatedHeaders, rows: updatedRows };
};

const generateHtml = (tableData: TableData) => {
  let htmlString = '<table><thead><tr>';

  // Generate header row
  tableData.headers.forEach(header => {
    htmlString += `<th>${header.name}</th>`;
  });
  htmlString += '</tr></thead><tbody>';

  // Generate body rows
  tableData.rows.forEach((row, idx) => {
    // Ignore the last row since it will always be the +New button
    if (idx !== tableData.rows.length - 1) {
      htmlString += '<tr>';
      tableData.headers.forEach(header => {
        const cellValue = row.cells[header.id]?.value;
        htmlString += `<td>${cellValue ? cellValue : ''}</td>`;
      });
      htmlString += '</tr>';
    }
  });

  htmlString += '</tbody></table>';

  return htmlString;
};

const generateTableDataFromHTML = (content: string, isEditable: boolean): TableData => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  const table = doc.querySelector('table');
  const headers: TableFormFieldConfig.ColumnDef[] = [];
  if (table) {
    // Get column names
    const headerCells = table.querySelectorAll('thead th');
    headerCells.forEach(header => {
      const columnDef: TableFormFieldConfig.ColumnDef = {
        id: MuidUtils.randomMuid(),
        name: header ? (header as HTMLTableCellElement).innerText?.trim() : '',
        columnType: 'Text',
      };
      headers.push(columnDef);
    });

    // Get row data
    const rowElements = table.querySelectorAll('tbody tr');
    const rows: TableFieldValue.Row[] = [];
    Array.from(rowElements).map(row => {
      const cells = row.querySelectorAll('td');
      if (cells.length > 0) {
        const rowData: TableFieldValue.Row = {
          id: MuidUtils.randomMuid(),
          cells: Array.from(cells).reduce((acc, cell, index) => {
            // Map to column id
            acc[`${headers[index].id}`] = {
              value: cell.innerText,
            };
            return acc;
          }, {} as Record<string, TableFieldValue.Cell>),
        };
        rows.push(rowData);
      }
    });
    if (isEditable) {
      rows.push({
        id: MuidUtils.randomMuid(),
        isFullWidth: true,
        cells: {},
      });
    }

    return { headers, rows };
  }
  return { headers: [], rows: [] };
};

const handleAddRow = (tableData: TableData) => {
  const newRow: TableFieldValue.Row = {
    id: MuidUtils.randomMuid(),
    cells: tableData.headers.reduce((acc, header) => {
      acc[header.id] = { value: '' };
      return acc;
    }, {} as Record<string, { value: string | number | null }>),
  };
  const updatedRows = [...tableData.rows.slice(0, -1), newRow, tableData.rows[tableData.rows.length - 1]];
  return { headers: tableData.headers, rows: updatedRows };
};

export const TableContentUtils = {
  generateHtml,
  generateTableDataFromHTML,
  handleAddRow,
  handleInsertColumn,
  handleRenameColumn,
};
