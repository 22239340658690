import * as React from 'react';
import { UIActorProvider, useSharedContext } from 'app/pages/forms/_id/shared';
import {
  FormEditorPageActorRefProvider,
  FormEditorPageActorSelectors,
  makeFormEditorPageMachine,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useInterpret, useSelector } from '@xstate/react';
import { TemplateSettingsModalProvider } from 'app/pages/templates/_id/components/template-settings-modal/template-settings-modal-context';
import { WorkflowEditorPage } from './editor.component';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { AutomationsModalWrapper } from 'app/pages/templates/_id/automation';
import { TaskTemplate, TemplateRevisionStatus, TemplateType } from '@process-street/subgrade/process';
import { AiGeneratedWorkflowSettingsModalV2 } from 'app/pages/templates/_id/components/ai-generated-workflow-settings-modal';
import { HighlightedTasksContextProvider } from './context';
import { Dictionary } from 'lodash';
import _isEqual from 'lodash/isEqual';
import { isTemplateRevisionEqual } from './helpers/is-template-revision-equal';
import { AnalyticsConstants } from '@process-street/subgrade/analytics';
import { AnalyticsService } from 'app/components/analytics/analytics.service';
import { Editor } from './components';

const { Key, Event } = AnalyticsConstants;

const TriggersModal = React.lazy(() =>
  import('features/automations/components/workflow-automations/components/template/modal').then(
    ({ TriggersModal }) => ({
      default: TriggersModal,
    }),
  ),
);

export const WorkflowEditorWrapper = () => {
  const sharedContext = useSharedContext();
  const machine = React.useRef(makeFormEditorPageMachine({ sharedContext })).current;
  const actor = useInterpret(machine);
  const aiWorkflowGenerationActor = useSelector(
    actor,
    FormEditorPageActorSelectors.getAiWorkflowGeneratorActorRef,
    _isEqual,
  );
  const uiActorRef = useSelector(actor, FormEditorPageActorSelectors.getUIActorRef, _isEqual);
  const [highLightedTasksMap, setHighlightedTasksMap] = React.useState<Dictionary<TaskTemplate>>();

  const template = useSelector(actor, FormEditorPageActorSelectors.getTemplate, _isEqual);

  const draftTemplateRevision = useSelector(
    actor,
    FormEditorPageActorSelectors.getTemplateRevision,
    isTemplateRevisionEqual,
  );

  React.useEffect(
    function trackIntercomEvents() {
      if (template) {
        const props = {
          [Key.TEMPLATE_ID]: template.id,
          [Key.TEMPLATE_NAME]: template.name,
        };
        AnalyticsService.trackEvent(Event.TEMPLATE_VIEWED, props);
      }
    },
    [template],
  );

  if (!template || !draftTemplateRevision) return null;

  return (
    <DndProvider backend={HTML5Backend}>
      <FormEditorPageActorRefProvider actor={actor}>
        <TemplateSettingsModalProvider templateId={template.id} templateRevisionId={draftTemplateRevision.id}>
          <ThemeProvider2024>
            <UIActorProvider uiActorRef={uiActorRef}>
              <AutomationsModalWrapper templateRevision={draftTemplateRevision} templateType={TemplateType.Playbook}>
                <HighlightedTasksContextProvider
                  highLightedTasksMap={highLightedTasksMap}
                  setHighlightedTasksMap={setHighlightedTasksMap}
                >
                  <Editor.FocusBar />

                  <WorkflowEditorPage template={template} templateRevision={draftTemplateRevision} />
                </HighlightedTasksContextProvider>

                {draftTemplateRevision.status === TemplateRevisionStatus.Draft && (
                  <AiGeneratedWorkflowSettingsModalV2
                    templateId={template.id}
                    templateRevisionId={draftTemplateRevision.id}
                    send={aiWorkflowGenerationActor.send}
                  />
                )}

                <React.Suspense fallback="">
                  <TriggersModal templateRevision={draftTemplateRevision} />
                </React.Suspense>
              </AutomationsModalWrapper>
            </UIActorProvider>
          </ThemeProvider2024>
        </TemplateSettingsModalProvider>
      </FormEditorPageActorRefProvider>
    </DndProvider>
  );
};
