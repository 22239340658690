import * as React from 'react';
import { AssignmentPickerWrapper } from '../assignment-picker/wrapper';
import { FieldType, TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import { Avatar, IconButton, ButtonGroup, AvatarBadge, Icon } from 'components/design/next';
import { useTaskAssignments } from './use-task-assignments';
import { match, P } from 'ts-pattern';
import { getAvatar } from 'components/common/Avatar';
import { ViewAssigneesMenu } from './view-assignees-menu';

export interface AssignTaskButtonProps {
  taskTemplate: TaskTemplate;
  templateRevision: TemplateRevision;
  isEditable?: boolean;
  isDisabled?: boolean;
}

export const AssignTaskButton: React.FC<React.PropsWithChildren<AssignTaskButtonProps>> = ({
  taskTemplate,
  templateRevision,
  isEditable = false,
  isDisabled = false,
}) => {
  const { assignees, uniqueRules } = useTaskAssignments({ taskTemplate, templateRevision });
  const hasAssignments = assignees.length + uniqueRules.length > 0;

  const [firstUser] = assignees
    .slice()
    .sort((a, b) => a.username.toLowerCase().localeCompare(b.username.toLowerCase()));
  const total = assignees.length + uniqueRules.length;
  const coverUser = (firstUser ?? uniqueRules[0]) as typeof firstUser | undefined | typeof uniqueRules[number];

  if (!isEditable && !hasAssignments) return null;

  return (
    <ButtonGroup
      isAttached
      sx={{
        '> span:first-of-type:not(:nth-last-child(2)) > button': { borderEndRadius: 0 },
        '> button:not(:only-child)': { borderStartRadius: 0, ml: '-px' },
      }}
    >
      {isEditable && (
        <AssignmentPickerWrapper
          taskTemplates={taskTemplate}
          templateRevision={templateRevision}
          hasAssignments={hasAssignments}
          disabled={isDisabled}
        />
      )}

      {hasAssignments && (
        <ViewAssigneesMenu
          taskTemplate={taskTemplate}
          templateRevision={templateRevision}
          isEditable={isEditable}
          assignees={assignees}
          rules={uniqueRules}
        >
          <IconButton
            aria-label="View assignees"
            icon={
              <Avatar
                size="xs"
                bg="gray.300"
                {...match(coverUser)
                  .with({ widget: { fieldType: FieldType.Members } }, () => ({
                    icon: <Icon icon="users" size="4" />,
                  }))
                  .with({ widget: { fieldType: FieldType.Email } }, () => ({
                    icon: <Icon icon="envelope" size="4" />,
                  }))
                  .with({ avatarUrl: P.string }, { avatarFile: { id: P.string } }, { username: P.string }, user => {
                    const avatar = getAvatar(user);
                    return {
                      src: avatar.url,
                      name: user.username,
                    };
                  })
                  .otherwise(() => ({}))}
              >
                {total > 1 && (
                  <AvatarBadge fontSize="8px" h="1em" minW="1em" px="0.5" bg="gray.400" border="none">
                    {total}
                  </AvatarBadge>
                )}
              </Avatar>
            }
            {...{
              iconSpacing: '0',
              variant: 'outline',
              size: 'sm',
              borderWidth: 'px',
              borderColor: isEditable ? 'gray.300' : 'transparent',
              justifyContent: 'center',
            }}
            sx={{
              '&': { px: 1 },
            }}
          />
        </ViewAssigneesMenu>
      )}
    </ButtonGroup>
  );
};
