// Make sure the rich text html string at least has font family set
import { toResult } from '@process-street/subgrade/util';
import { deserializeHtml, serialize } from 'pages/pages/_id/edit/page/utils/serialization';
import { trace } from 'components/trace';
import {
  SendRichEmailDescendant,
  SendRichEmailEditor,
  SendRichEmailElement,
  SendRichEmailValue,
} from './send-rich-email-plate-types';
import { getNodeChildren } from '@udecode/slate';
import memoize from 'lodash/memoize';
import { TableFormFieldUtils } from '@process-street/subgrade/process/widget-utils/table-form-field-utils';

export enum StyleStringId {
  BaseStyles = 'baseStyles',
  TableStyles = 'tableStyles',
}

export namespace EditorUtils {
  /** Basic style string added when creating a Send Rich Email form field via backend (AI generation)
   * and existing form fields */
  const BASE_STYLE =
    '*{font-family:Inter,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",system-ui,sans-serif}';
  const LEGACY_STYLE_STRING = `<style>${BASE_STYLE}</style>`;
  const STYLE_STRING = `<style id='${StyleStringId.BaseStyles}'>${BASE_STYLE}</style>`;

  const getStyleStringRegexpById = (id: string) => new RegExp(`<style id=["']${id}["']>(.|\n)*?<\\/style>`);

  const removeAllStylesWithId = (html: string) =>
    Object.values(StyleStringId).reduce((acc, id) => acc.replace(getStyleStringRegexpById(id), ''), html);
  const withStyleString = (body: string, isTableFormFieldEnabled = false) => {
    const styleString = STYLE_STRING + (isTableFormFieldEnabled ? getTableStyles() : '');
    // Gmail expects style string to be in the head tag
    return isTableFormFieldEnabled
      ? `<html><head>${styleString}</head><body>${body}</body></html>`
      : body.concat(styleString);
  };
  export const withoutStyleString = (html: string) => removeAllStylesWithId(html.replace(LEGACY_STYLE_STRING, ''));

  const logger = trace({ name: 'EditorUtils' });

  export const convertBodyToElements = (body: string): SendRichEmailValue => {
    const empty = [{ type: 'p' as const, children: [{ text: '' }] }];
    // TODO add normalizer to editor
    if (body === '') {
      return empty;
    }
    return toResult(() => deserializeHtml(withoutStyleString(body))).fold(
      () => {
        logger.error('Unable to parse body', { body });
        return empty;
      },
      x => x as any as SendRichEmailValue,
    );
  };

  const serializeElements = (elements: SendRichEmailDescendant[]) => elements.map(serialize).join('');
  export const serializeElementsWithStyleString = (elements: SendRichEmailElement[], isTableFormFieldEnabled = false) =>
    withStyleString(serializeElements(elements), isTableFormFieldEnabled);

  export const getEditorContent = (editor: SendRichEmailEditor): string => {
    const elements = Array.from(getNodeChildren(editor, [])).map(([e, _n]) => e);
    return serializeElements(elements);
  };

  export const getEditorSelection = (editor: SendRichEmailEditor): string => {
    if (editor.selection) {
      const elements = editor.getFragment();
      return removeOuterTags(serializeElements(elements));
    } else {
      return '';
    }
  };

  const REMOVE_TAGS_REGEXP = /^(<[^>/]*>)*|(<\/[^>]*>)*$/g;

  export const removeOuterTags = (content: string) => content.replace(REMOVE_TAGS_REGEXP, '');
}

/**
 * Returns table style string to be added to rich email fields.
 * For Text widget (Angular), style is in template-text-widget.scss.
 * These contain some additional styles because in emails/iframes, there are no base CSS styles existing.
 * Also, in Gmail, CSS nesting is not supported, neither are pseudo-classes.
 */
const getTableStyles = memoize(() => {
  const gray50 = '#F9FAFB';
  const gray100 = '#EEF0F2';
  const gray200 = '#DEE4E8';
  const gray300 = '#C2CDD6';
  const gray800 = '#151D23';
  const fontSm = '14px';
  const result = `
<style id='${StyleStringId.TableStyles}'>
  .${TableFormFieldUtils.WRAPPER_CSS_CLASS} {
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid ${gray300};
    overflow-x: auto;
  }

  .${TableFormFieldUtils.WRAPPER_CSS_CLASS} * {
    box-sizing: border-box;
  }

  .${TableFormFieldUtils.WRAPPER_CSS_CLASS} table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .${TableFormFieldUtils.WRAPPER_CSS_CLASS} thead {
    background-color: ${gray100};
    border-bottom: 1px solid ${gray300};
  }

  .${TableFormFieldUtils.WRAPPER_CSS_CLASS} thead th {
    font-size: ${fontSm};
    font-weight: bold;
    color: ${gray800};
    min-width: 200px;
  }

  .${TableFormFieldUtils.WRAPPER_CSS_CLASS} tbody tr:nth-child(even) {
    background-color: ${gray50};
  }

  .${TableFormFieldUtils.WRAPPER_CSS_CLASS} tbody tr {
    border-top: 1px solid ${gray200};
  }

  .${TableFormFieldUtils.WRAPPER_CSS_CLASS} th, td {
    padding: 4px 17px;
    height: 39px;
    text-align: left;
  }
</style>`;
  return result.replace(/(\n|\s{2,})/g, ''); // basic, safe minification
});
