import { Box, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import {
  isApproval,
  isHeading,
  TaskTemplate,
  TaskTemplateTaskType,
  TemplateRevision,
} from '@process-street/subgrade/process';
import { Icon, MotionWrapper } from 'app/components/design/next';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { AnimatePresence, MotionProps } from 'framer-motion';
import * as React from 'react';
import { match } from 'ts-pattern';
import { AutomationMenuItem } from './automation-menu-item';
import { ConditionalLogicMenuItem } from './conditional-logic-menu-item';
import { StopTaskMenuItem } from './stop-task-menu-item';

export type TaskListItemMenuProps = {
  taskTemplate: TaskTemplate;
  templateRevision: TemplateRevision;
  hasConditionalLogic: boolean;
  isSelected: boolean;
  onDelete: () => void;
  onDuplicate: () => void;
  onAddStopTask: (taskTemplateId: TaskTemplate['id'], taskTemplate: Partial<TaskTemplate>) => void;
  switchSelectedTask: (taskTemplate: TaskTemplate) => void;
};

export const TaskListItemMenu = ({
  taskTemplate,
  templateRevision,
  hasConditionalLogic,
  isSelected,
  onDelete,
  onDuplicate,
  onAddStopTask,
  switchSelectedTask,
}: TaskListItemMenuProps) => {
  return (
    <Menu placement="bottom" isLazy>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={IconButton}
            size="sm"
            opacity={isSelected ? 1 : 0}
            variant="ghost"
            icon={<Icon icon="ellipsis-h" size="3" color="gray.600" />}
            aria-label="Task menu"
            _groupHover={{ opacity: 1 }}
          />

          <Portal>
            <MenuList zIndex="popover">
              {isOpen && (
                <ThemeProvider2024>
                  <AnimatePresence>
                    <>
                      {shouldShowMenuItem('duplicate', taskTemplate) && (
                        <MotionWrapper {...getMenuItemMotionProps(3)}>
                          <MenuItem
                            fontSize="md"
                            icon={<Icon icon="copy" size="3.5" color="gray.500" />}
                            onClick={onDuplicate}
                            color="gray.600"
                          >
                            Duplicate
                          </MenuItem>
                        </MotionWrapper>
                      )}
                      {shouldShowMenuItem('conditional-logic', taskTemplate) && (
                        <MotionWrapper {...getMenuItemMotionProps(0)}>
                          <Box>
                            <ConditionalLogicMenuItem
                              templateRevision={templateRevision}
                              taskTemplate={taskTemplate}
                              hasConditionalLogic={hasConditionalLogic}
                            />
                          </Box>
                        </MotionWrapper>
                      )}

                      {shouldShowMenuItem('automate', taskTemplate) && (
                        <MotionWrapper {...getMenuItemMotionProps(2)}>
                          <AutomationMenuItem switchSelectedTask={switchSelectedTask} taskTemplate={taskTemplate} />
                        </MotionWrapper>
                      )}

                      {shouldShowMenuItem('stop', taskTemplate) && (
                        <MotionWrapper {...getMenuItemMotionProps(1)}>
                          <StopTaskMenuItem
                            templateRevision={templateRevision}
                            taskTemplate={taskTemplate}
                            onClick={onAddStopTask}
                          />
                        </MotionWrapper>
                      )}

                      {shouldShowMenuItem('delete', taskTemplate) && (
                        <MotionWrapper {...getMenuItemMotionProps(3)}>
                          <MenuItem
                            fontSize="md"
                            icon={<Icon icon="trash-can" size="3.5" color="red.500" />}
                            color="red.500"
                            onClick={onDelete}
                          >
                            Delete
                          </MenuItem>
                        </MotionWrapper>
                      )}
                    </>
                  </AnimatePresence>
                </ThemeProvider2024>
              )}
            </MenuList>
          </Portal>
        </>
      )}
    </Menu>
  );
};

const getMenuItemMotionProps = (index: number): MotionProps => {
  return {
    initial: { opacity: 0, y: -5 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -5 },
    transition: { delay: 0.05 * index, ease: 'easeInOut' },
  };
};

const shouldShowMenuItem = (menuItem: string, taskTemplate: TaskTemplate) => {
  return match({ taskTemplate, menuItem })
    .with({ taskTemplate: { taskType: TaskTemplateTaskType.AI }, menuItem: 'delete' }, () => true)
    .with({ taskTemplate: { taskType: TaskTemplateTaskType.AI } }, () => false)
    .with({ menuItem: 'duplicate' }, () => !isApproval(taskTemplate))
    .with({ menuItem: 'automate' }, () => !isHeading(taskTemplate))
    .with({ menuItem: 'stop' }, () => !isHeading(taskTemplate))
    .otherwise(() => true);
};
