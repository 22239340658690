import * as React from 'react';
import { SubtasksFormFieldActor } from './subtasks-content-machine';
import { useActor } from '@xstate/react';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { ContentFieldRecentlyMovedIndicator } from '../common/content-field-recently-moved-indicator';
import { Box } from 'components/design/next';
import { FormFieldLabel } from '../../form-fields/common/form-field-label';
import {
  SubtaskEditor,
  SubtasksFormField,
} from 'app/features/one-off-tasks/components/form-fields/subtasks-form-field';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { subtasksSchema } from './subtasks-content-schema';
import { MultiSelectFieldValue, SelectFormFieldConfig } from '@process-street/subgrade/process';
import { useDebouncedCallback } from 'use-debounce';
import { useAiGenerationSlice } from 'app/pages/templates/_id/components/ai-generated-workflow-settings-modal/store';
import { ViewModeInteractionWrapper } from '../../view-mode-interaction-wrapper/view-mode-interaction-wrapper';

export interface SubtasksContentProps {
  actor: SubtasksFormFieldActor;
  isFirst?: boolean;
  isLast?: boolean;
}

const DEBOUNCE_DELAY = 500;
const ITEMS_FIELD_NAME = 'items';

export const SubtasksContent: React.FC<React.PropsWithChildren<SubtasksContentProps>> = ({
  actor,
  isFirst = false,
  isLast = false,
}) => {
  const [state, send] = useActor(actor);
  const { widget, template, recentlyMovedFrom, labelActor, isReadOnly } = state.context;
  const { isGenerating } = useAiGenerationSlice();
  const ref = React.useRef<HTMLDivElement | null>(null);

  const handleOnSubmit = (values: SelectFormFieldConfig) => {
    send({ type: 'UPDATE_WIDGET', widget: { ...widget, config: { items: values.items } } });
  };

  const handleChange = (value: MultiSelectFieldValue[]) => {
    setFieldValue(ITEMS_FIELD_NAME, value);
    debouncedSubmit();
  };
  const { handleSubmit, errors, touched, values, setFieldValue, resetForm } = useFormik({
    onSubmit: handleOnSubmit,
    initialValues: { items: widget.config.items },
    validationSchema: subtasksSchema,
  });

  const debouncedSubmit = useDebouncedCallback(() => {
    handleSubmit();
  }, DEBOUNCE_DELAY);

  React.useEffect(
    function syncFormWhenAiGenerating() {
      if (isGenerating) {
        resetForm({ values: { items: widget.config.items } });
      }
    },
    [widget.config.items, isGenerating, resetForm],
  );

  const handleBlur = () => debouncedSubmit.flush();

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer alignItems="flex-start">
        {recentlyMovedFrom && <ContentFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
        {labelActor && <FormFieldLabel isReadOnly={isReadOnly} actor={labelActor} />}
        <Box
          w="full"
          mt={2}
          ref={node => {
            ref.current = node;
            if (node && !state.context.inputNode) {
              send({ type: 'SET_NODE', node });
            }
          }}
        >
          <ViewModeInteractionWrapper templateId={template.id}>
            <form onSubmit={handleSubmit}>
              <SubtasksFormField
                value={values.items as MultiSelectFieldValue[]}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.items as unknown as FormikErrors<{ name: string }>[]}
                touched={touched.items as unknown as FormikTouched<{ name: boolean }>[]}
                editorType={SubtaskEditor.WFEditor}
                templateRevisionId={widget.templateRevision.id}
                groupId={widget.header.group.id}
                isReadOnly={isReadOnly}
              />
            </form>
          </ViewModeInteractionWrapper>
        </Box>
        {!isReadOnly && (
          <>
            <WidgetListItemDragIcon />
            <FormsWidgetMenu>
              <FormsWidgetMenuItems.Required widget={widget} />
              <FormsWidgetMenuItems.Duplicate />
              <FormsWidgetMenuItems.MoveToStep widget={widget} />
              <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
              <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
              <FormsWidgetMenuItems.Delete />
            </FormsWidgetMenu>
          </>
        )}
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};
