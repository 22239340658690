import React from 'react';
import { match, P } from 'ts-pattern';
import { useHover } from 'react-use';
import { Template } from '@process-street/subgrade/process';
import { queryClient } from 'components/react-root';
import { HStack, Icon, Text, useDisclosure, Box, BoxProps, VStack, Flex } from 'components/design/next';
import { CoverImageByTemplateIdQuery } from 'features/cover-image/query-builder';
import { CoverImage } from '@process-street/subgrade/core';
import { CDNImage } from 'components/cdn-image';
import { EditCoverLayer } from 'features/cover-image/components/common/edit-cover-layer';
import { useMatch } from '@process-street/adapters/navigation';

const CoverImageUploadModal = React.lazy(() =>
  import('features/cover-image/components/common/modal').then(({ CoverImageUploadModal }) => ({
    default: CoverImageUploadModal,
  })),
);

type HoverableContainerProps = {
  children: (props: { hovered: boolean }) => React.ReactElement;
} & Omit<BoxProps, 'children'>;

const HoverableContainer: React.FC<HoverableContainerProps> = ({ children, ...props }) => {
  const [hoverable] = useHover((hovered: boolean) => (
    <Box data-testid="cover-image-container" height="50" {...props}>
      {children({ hovered })}
    </Box>
  ));
  return hoverable;
};

export type PageCoverImageProps = {
  templateId: Template['id'];
  isEditable: boolean;
  coverImage?: CoverImage;
} & BoxProps;

export const PageCoverImage: React.FC<React.PropsWithChildren<PageCoverImageProps>> = ({
  templateId,
  isEditable,
  coverImage,
  ...props
}) => {
  const uploadDisclosure = useDisclosure();
  const isViewV2 = useMatch('pageViewV2');

  const onFinish = () => {
    uploadDisclosure.onClose();
    queryClient.invalidateQueries(CoverImageByTemplateIdQuery.getKey({ templateId }));
  };

  return (
    <>
      {match<[CoverImage | undefined, boolean, boolean]>([coverImage, isEditable, Boolean(isViewV2)])
        .with([undefined, true, false], () => (
          <HStack
            role="button"
            spacing="2"
            onClick={uploadDisclosure.onOpen}
            cursor="pointer"
            height="200px"
            w="full"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Flex alignItems="center" gap={2} pt={15}>
              <Icon icon="image" size="4" color="gray.400" />
              <Text aria-label="add cover" color="gray.400">
                Add cover
              </Text>
            </Flex>
          </HStack>
        ))
        .with([undefined, false, true], () => <Box height="200px" w="full" backgroundColor="gray.100" />)
        .with([P.not(undefined), P.any, P.any], ([coverImage, _]) => (
          <HoverableContainer {...props}>
            {({ hovered }) => (
              <>
                <CDNImage
                  alt={coverImage.s3File.originalName}
                  s3File={coverImage.s3File}
                  height="50"
                  transformation={{
                    height: 200,
                    width: window.innerWidth,
                  }}
                />
                {isEditable && hovered && (
                  <EditCoverLayer
                    onClickChange={uploadDisclosure.onOpen}
                    templateId={templateId}
                    height={15}
                    top="34px"
                    hideOverlay
                  />
                )}
              </>
            )}
          </HoverableContainer>
        ))
        .otherwise(() => null)}

      <React.Suspense fallback="">
        {isEditable && (
          <CoverImageUploadModal {...uploadDisclosure} templateId={templateId} onFinish={onFinish}>
            <VStack>
              <Text align="center" variant="-1" color="gray.500" fontWeight="normal">
                Cover image will appear as a banner at the top of any page.
              </Text>

              <Text variant="-1" color="gray.500" fontWeight="normal">
                Images wider than 1500px work best
              </Text>
            </VStack>
          </CoverImageUploadModal>
        )}
      </React.Suspense>
    </>
  );
};
