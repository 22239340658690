import * as React from 'react';
import { SendRichEmailFormFieldValue, SendRichEmailFormFieldWidget } from '@process-street/subgrade/process';
import { SendRichEmailTimestamp } from '../send-rich-email-timestamp';
import { HStack, Text, VStack } from 'components/design/next';
import { useResolvedMergeTagsByChecklistRevisionIdQuery } from 'features/merge-tags/query-builder';
import { MergeTagMode, MergeTagTarget } from '@process-street/subgrade/form';
import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import { useParseChecklistMergeTagsByTarget } from '../send-rich-email/checklist/use-parse-checklist-merge-tags-by-target';

export interface ApprovalSubjectSendRichEmailProps {
  formFieldValue?: SendRichEmailFormFieldValue;
  widget?: SendRichEmailFormFieldWidget;
}

export const ApprovalSubjectSendRichEmail: React.FC<React.PropsWithChildren<ApprovalSubjectSendRichEmailProps>> = ({
  formFieldValue: { fieldValue, checklistRevision } = {},
  widget,
}) => {
  const isRichText = widget?.config.emailFormat === 'RichTextOrHtml';
  const checklistRevisionId = checklistRevision?.id;
  const taskId = widget?.header.taskTemplate?.id;
  const mergeTagsQuery = useResolvedMergeTagsByChecklistRevisionIdQuery({
    mergeTagTarget: MergeTagTarget.APPROVAL_TASK,
    checklistRevisionId,
    taskId,
  });

  const replaceEmailsMergeTags = (emails: string[]) =>
    MergeTagStringReplacementUtils.replaceResolvedTagsValues(
      mergeTagsQuery.data ?? [],
      emails.join(', ') || '-',
      MergeTagMode.PLAINTEXT,
    );

  const to = replaceEmailsMergeTags(fieldValue?.to ?? widget?.config.to ?? []);
  const cc = replaceEmailsMergeTags(fieldValue?.cc ?? widget?.config.cc ?? []);
  const bcc = replaceEmailsMergeTags(fieldValue?.bcc ?? widget?.config.bcc ?? []);
  const subject = MergeTagStringReplacementUtils.replaceResolvedTagsValues(
    mergeTagsQuery.data ?? [],
    fieldValue?.subject ?? widget?.config.subject ?? '-',
    MergeTagMode.PLAINTEXT,
  );
  const body = fieldValue?.body ?? widget?.config.richEditorBody ?? '-';

  const parseTags = useParseChecklistMergeTagsByTarget({
    mergeTagTarget: MergeTagTarget.APPROVAL_TASK,
    checklistRevisionId,
    taskId,
  });

  return fieldValue ? (
    <VStack alignItems="flex-start" w="full">
      <HStack spacing="4" w="full">
        <Text minW="80px" textAlign="right" fontWeight="500" color="gray.600" lineHeight="1.5">
          To
        </Text>
        <Text color="gray.400" lineHeight="1.5">
          {to}
        </Text>
      </HStack>

      <HStack spacing="4" w="full">
        <Text minW="80px" textAlign="right" fontWeight="500" color="gray.600" lineHeight="1.5">
          CC
        </Text>
        <Text color="gray.400" lineHeight="1.5">
          {cc}
        </Text>
      </HStack>

      <HStack spacing="4" w="full">
        <Text minW="80px" textAlign="right" fontWeight="500" color="gray.600" lineHeight="1.5">
          BCC
        </Text>
        <Text color="gray.400" lineHeight="1.5">
          {bcc}
        </Text>
      </HStack>

      <HStack spacing="4" w="full">
        <Text minW="80px" textAlign="right" fontWeight="500" color="gray.600" lineHeight="1.5">
          Subject
        </Text>
        <Text color="gray.400" lineHeight="1.5">
          {subject}
        </Text>
      </HStack>

      <HStack alignItems="flex-start" spacing="4" w="full">
        <Text minW="80px" textAlign="right" fontWeight="500" color="gray.600" lineHeight="1.5">
          Body
        </Text>
        <Text
          color="gray.400"
          lineHeight="1.5"
          dangerouslySetInnerHTML={{
            __html: parseTags({ content: body, mode: isRichText ? MergeTagMode.HTML : MergeTagMode.PLAINTEXT }),
          }}
        />
      </HStack>

      <SendRichEmailTimestamp {...fieldValue} />
    </VStack>
  ) : null;
};
