import * as React from 'react';
import { Box, Flex, Grid, GridItem, useBreakpointValue, VStack } from 'components/design/next';
import { PagesEditor } from './components';
import { useSelector } from '@xstate/react';
import {
  FormEditorPageActorSelectors,
  useFormEditorPageActorRef,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import _isEqual from 'lodash/isEqual';
import { Template } from '@process-street/subgrade/process';
import { PAGES_FOCUS_BAR_HEIGHT } from '../edit/page/components/focus-bar';
import { PageMedia } from './components/page-media';

export type PagesEditorPageProps = {
  template: Template;
};

export const PagesEditorPage = ({ template }: PagesEditorPageProps) => {
  const actor = useFormEditorPageActorRef();
  const uiActorRef = useSelector(actor, FormEditorPageActorSelectors.getUIActorRef, _isEqual);
  const isMobile = useBreakpointValue({ base: true, lg: false }) ?? false;
  React.useEffect(
    function notifyScreenSizeToUiMachine() {
      uiActorRef.send({ type: 'SET_IS_MOBILE', isMobile });
    },
    [isMobile, uiActorRef],
  );

  React.useEffect(
    function updatePageTitle() {
      if (template.name) {
        document.title = `${template.name} | Process Street`;
      }
    },
    [template.name],
  );
  return (
    <VStack w="full" alignItems="stretch" justifyContent="stretch" spacing={0}>
      <PagesEditor.FocusBar />
      <Grid
        gridTemplateColumns={{ base: '1fr 0', lg: '1fr  155px' }}
        gridTemplateRows={{ base: 'auto', lg: '1fr' }}
        position="relative"
        pt={PAGES_FOCUS_BAR_HEIGHT}
        background="transparent"
        minW="100%"
      >
        <GridItem gridColumn="1/-1" position="relative">
          <PageMedia template={template} />
        </GridItem>
        <GridItem
          w="full"
          as={Flex}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          px={{ base: 8, md: 0 }}
        >
          <Box
            maxW="888px"
            w="full"
            background="white"
            marginTop="-100px"
            borderRadius="md"
            sx={{
              '.image-content__aspect-ratio': { w: 'full', maxW: 'unset' },
              '.image-content': { maxW: 'unset' },
              '.file-container': { w: 'full' },
              '[data-component="FileUpload"]': { w: 'full' },
            }}
            p={11}
            zIndex={1}
          >
            <PagesEditor.Title />
            <PagesEditor.WidgetsList />
          </Box>
        </GridItem>
        <GridItem>
          <PagesEditor.InsertWidgetsSection />
        </GridItem>
      </Grid>
    </VStack>
  );
};
