import * as React from 'react';
import { Grid, GridItem, useBreakpointValue, VStack } from 'components/design/next';
import { useSelector } from '@xstate/react';
import { FormEditorPageActorSelectors, FormEditorPageMachine } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { TOP_BAR_HEIGHT_CSS_VAR } from 'app/pages/forms/_id/shared';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-context';
import { useDisplayWorkflowSetupModal } from 'app/components/focus-bar/workflow/right-button-group/edit/use-display-workflow-setup-modal';
import { WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT } from 'app/components/focus-bar/workflow/focus-bar-hstack';
import { useStateParam } from 'app/hooks/use-state-param';
import { useRedirectToTaskOnLoad } from './hooks';
import { TaskTemplateTaskType, Template, TemplateRevision } from '@process-street/subgrade/process';
import { useSyncAiGenerationAblyMessages } from 'app/pages/forms/_id/edit/ai-workflow-generation-machine/hooks';
import { Option } from 'space-monad';
import { StateFrom } from 'xstate';
import { Editor } from './components';
import { isPickedPropertiesEqual } from './helpers/is-picked-props-equal';
import { BackgroundImage } from './components/background-image/background-image';
import { MoveToStepModal } from 'app/pages/forms/_id/edit/components/forms-widget-menu/move-to-step/move-to-step-modal';
import { chainComparators } from './helpers/comparator-utils';
import { isTaskTemplateHeadingEqual } from './helpers/is-task-template-heading-equal';
import _isEqual from 'lodash/isEqual';
import { useNgStateModalControls } from 'app/components/ng-state-modal-controls';
import { AppModalName } from 'app/app.constants';
import { useAutomationSelector } from 'app/pages/templates/_id/automation/components/selector/context';
import { SolutionTypeTag } from '@process-street/subgrade/automation';
import { Muid } from '@process-street/subgrade/core';

export const WorkflowEditorPage = ({
  template,
  templateRevision,
}: {
  template: Template;
  templateRevision: TemplateRevision;
}) => {
  const actor = useFormEditorPageActorRef();
  const uiActorRef = useSelector(actor, FormEditorPageActorSelectors.getUIActorRef, _isEqual);
  const { isOpen: isAutomationsModalOpenFromNgState } = useNgStateModalControls(AppModalName.Automations);
  const isOpenFromNgStateRef = React.useRef(false);
  const [_, sendAutomationsMachine] = useAutomationSelector();

  React.useEffect(
    function openAutomationsModalFromNgState() {
      if (isAutomationsModalOpenFromNgState && !isOpenFromNgStateRef.current) {
        isOpenFromNgStateRef.current = true;
        setTimeout(() => {
          sendAutomationsMachine({
            type: 'SOLUTION_TYPE_SELECTED',
            payload: { solutionTypeTag: SolutionTypeTag.WhenTaskCheckedThen, modalView: 'all' },
          });
        }, 300);
      }
    },
    [isAutomationsModalOpenFromNgState, sendAutomationsMachine],
  );
  const hasCompletedInitialRedirect = React.useRef(false);

  const taskTemplateGroupId = useStateParam({ key: 'groupId' });

  const currentTaskTemplateSelector = React.useCallback(
    (state: StateFrom<FormEditorPageMachine>) => {
      return Option(taskTemplateGroupId)
        .map(groupId => FormEditorPageActorSelectors.getTaskTemplateByGroupId(groupId)(state))
        .get();
    },
    [taskTemplateGroupId],
  );

  const idAndStopComparator = isPickedPropertiesEqual(['id', 'stop']);
  const taskTemplateComparator = chainComparators(idAndStopComparator, isTaskTemplateHeadingEqual);
  const currentTaskTemplate = useSelector(actor, currentTaskTemplateSelector, taskTemplateComparator);

  const isCurrentTaskTemplateApproval = currentTaskTemplate?.taskType === TaskTemplateTaskType.Approval;
  const isLoading = useSelector(actor, FormEditorPageActorSelectors.isLoading, _isEqual);

  useDisplayWorkflowSetupModal();
  useSyncAiGenerationAblyMessages();

  const handleInitialRedirect = React.useCallback(() => {
    hasCompletedInitialRedirect.current = true;
  }, []);

  const isMobile = useBreakpointValue({ base: true, lg: false }) ?? false;
  React.useEffect(
    function notifyScreenSizeToUiMachine() {
      uiActorRef.send({ type: 'SET_IS_MOBILE', isMobile });
    },
    [isMobile, uiActorRef],
  );

  React.useEffect(
    function updatePageTitle() {
      if (template.name) {
        document.title = `${template.name} | Process Street`;
      }
    },
    [template.name],
  );

  return (
    <VStack w="full" alignItems="stretch" justifyContent="stretch" spacing={0} bgColor="brand.50">
      <BackgroundImage templateId={template.id} />

      {!hasCompletedInitialRedirect.current && (
        <RedirectToTaskOnLoad
          taskTemplateGroupId={taskTemplateGroupId}
          templateId={template.id}
          onRedirect={handleInitialRedirect}
          isLoading={isLoading}
        />
      )}

      <Grid
        gridTemplateColumns={{ base: '0 1fr 0', lg: `476px 1fr ${isCurrentTaskTemplateApproval ? '0' : '156px'}` }}
        h={`calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR})`}
        position="relative"
        pt={WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT}
        background="transparent"
      >
        <GridItem h="full">{<Editor.TaskListSection template={template} />}</GridItem>

        <GridItem
          pt={{ base: 8, md: 32, lg: 10 }}
          px={{ base: 2, md: 0 }}
          overflowY="auto"
          h="full"
          position="relative"
          display="flex"
          flexDirection="column"
          w="full"
        >
          {currentTaskTemplate && (
            <Editor.WidgetListSection
              currentTaskTemplate={currentTaskTemplate}
              template={template}
              templateRevision={templateRevision}
            />
          )}
        </GridItem>

        {currentTaskTemplate && currentTaskTemplate.taskType === TaskTemplateTaskType.Standard && (
          <GridItem>
            <Editor.InsertWidgetsSection />
          </GridItem>
        )}
      </Grid>

      <Editor.Modal />
      <MoveToStepModal />
    </VStack>
  );
};

type RedirectToTaskOnLoadProps = {
  onRedirect: () => void;
  isLoading: boolean;
  templateId: Muid;
  taskTemplateGroupId?: Muid;
};

const RedirectToTaskOnLoad = ({
  isLoading,
  templateId,
  taskTemplateGroupId,
  onRedirect,
}: RedirectToTaskOnLoadProps) => {
  useRedirectToTaskOnLoad({ isLoading, templateId, taskTemplateGroupId, onRedirect });

  return null;
};
