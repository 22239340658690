import { useStateParams } from '@process-street/adapters/navigation';
import { useInjector } from 'app/components/injection-provider';
import { trace } from 'app/components/trace';
import * as React from 'react';
import { useMount } from 'react-use';

export type SetupAnonymousAuthProps = React.PropsWithChildren<{}>;

const logger = trace({ name: 'SetupAnonymousAuth' });

export const SetupAnonymousAuth = ({ children }: SetupAnonymousAuthProps) => {
  const { AnonymousAuthService } = useInjector('AnonymousAuthService');
  const [hasInitialized, setHasInitialized] = React.useState(false);
  const { id: templateId } = useStateParams();

  useMount(() => {
    if (!templateId) {
      setHasInitialized(true);
      return;
    }

    AnonymousAuthService.templateViewAnonymousAuth(templateId.toString())
      .then(() => {
        setHasInitialized(true);
      })
      .catch(e => {
        logger.error('AnonymousAuthService.templateViewAnonymousAuth has failed.', e);
      });
  });

  if (!hasInitialized) {
    return null;
  }

  return <>{children}</>;
};

export function withAnonymousAuth<T extends React.ComponentType<any>>(WrappedComponent: T) {
  return (props: React.ComponentProps<T>) => (
    <SetupAnonymousAuth>
      <WrappedComponent {...props} />
    </SetupAnonymousAuth>
  );
}
