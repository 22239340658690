import * as React from 'react';
import { Button, HStack, Icon } from 'components/design/next';
import { useTableContentContext } from '../context';

export const AddNewRowRenderer: React.FC = () => {
  const { handleAddRow, isEditable } = useTableContentContext();
  if (!isEditable) {
    return null;
  }
  return (
    <HStack backgroundColor="blue.50">
      <Button
        w="100%"
        justifyContent="flex-start"
        leftIcon={<Icon icon="plus" size="3" variant="far" />}
        variant="ghost"
        colorScheme="gray"
        color="gray.300"
        fontWeight="400"
        _active={{ color: 'gray.500' }}
        _hover={{ color: 'gray.500' }}
        onClick={handleAddRow}
      >
        New
      </Button>
    </HStack>
  );
};
