import {
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBreakpointValue,
  useDisclosure,
  useToast,
  VStack,
} from 'components/design/next';
import * as React from 'react';
import { Breadcrumbs } from 'components/breadcrumbs';
import { TemplateMenuContext } from 'features/template/components/template-menu';

import { useQueryClient } from 'react-query';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import {
  GetTemplateQuery,
  UpdateTemplateStatusMutation,
  UpdateTemplateStatusMutationParams,
  useGetTemplateQuery,
  useUpdateTemplateStatusMutation,
} from 'features/template/query-builder';

import { TemplateStatus } from '@process-street/subgrade/process';
import { match } from 'ts-pattern';
import { DeleteTemplateButton } from 'features/template/components/template-menu/delete-template-button';

import {
  GetNewestTemplateRevisionsByTemplateIdQuery,
  useTemplateRevisionQuery,
} from 'features/template-revisions/query-builder';
import { useTemplateSettingsModalContext } from 'pages/templates/_id/components/template-settings-modal/template-settings-modal-context';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { TemplateCoverIcon } from 'features/cover-icon/components/template';
import { GetCoverIconByTemplateId } from 'features/cover-icon/query-builder';
import { Show } from '@chakra-ui/react';
import { TemplateBackButton, useBackButtonContext } from 'components/back-button-provider';
import { AnalyticsService } from 'components/analytics/analytics.service';
import { Global } from '@emotion/react';
import { useIsAnonymousUser } from 'app/hooks/use-is-anonymous-user';
import { baseCoverIconProps } from 'app/components/focus-bar/workflow';
import { useGetFocusBarColor } from '../../../edit/page/components/focus-bar/use-get-focus-bar-color';
import { TemplateNameEditor } from 'app/components/focus-bar/workflow/template-name-editor';
import { ViewMiddleButtonGroup } from './view-middle-button-group';
import { EditMiddleButtonGroup } from './edit-middle-button-group';
import { EditButtonGroup } from './edit-button-group';
import { useIsEditable } from 'app/features/rich-text';

export const FocusBar = () => {
  const { templateId, templateRevisionId } = useTemplateSettingsModalContext();
  if (!templateRevisionId) {
    throw new Error('Expecting template revision ID for this component.');
  }
  const templateRevisionQuery = useTemplateRevisionQuery({ templateRevisionId });
  // TODO fix this
  const isEditable = useIsEditable();
  const { shouldShowBackButton } = useBackButtonContext();

  const isAnonymousUser = useIsAnonymousUser();

  const templateQuery = useGetTemplateQuery({ templateId });
  const template = templateQuery.data;

  const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(templateId);

  const getFocusBarColor = useGetFocusBarColor();
  const queryClient = useQueryClient();

  const templateStatus = template?.status ?? TemplateStatus.Active;

  const unarchiveTemplateParams = React.useMemo<UpdateTemplateStatusMutationParams>(
    () => ({ templateId, status: 'Active' }),
    [templateId],
  );

  const toast = useToast();

  const { mutate: unarchiveTemplate } = useUpdateTemplateStatusMutation({
    mutationKey: UpdateTemplateStatusMutation.getKey(unarchiveTemplateParams),
    onSuccess: res => {
      AnalyticsService.trackEvent('template unarchived', {});
      queryClient.setQueryData(GetTemplateQuery.getKey({ templateId }), res);
      void queryClient.refetchQueries(GetNewestTemplateRevisionsByTemplateIdQuery.getKey({ templateId }));
      toast({
        status: 'success',
        title: `This workflow is now active`,
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: `We're having problems activating this workflow`,
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const canArchiveTemplate = permissionMap?.templateUpdate;

  const handleUnarchive = React.useCallback(() => {
    if (canArchiveTemplate) {
      unarchiveTemplate(unarchiveTemplateParams);
    }
  }, [canArchiveTemplate, unarchiveTemplate, unarchiveTemplateParams]);

  const [closeOnBlur, setCloseOnBlur] = React.useState(true);

  const templateMenuDisclosure = useDisclosure();
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const coverIconQuery = GetCoverIconByTemplateId.useQuery(
    { templateId },
    {
      enabled: Boolean(templateId),
    },
  );

  if (!templateId || !templateRevisionQuery.data) return null;

  return (
    <>
      {isAnonymousUser && (
        <Global
          styles={`
          ps-topbar {
            display: none;
          }

          .navbar-offset {
            padding-top: 0 !important;
          }
        `}
        />
      )}

      <HStack
        data-hide-on-print
        h={18}
        w="full"
        py="2"
        px={['4', null, '6']}
        borderBottomStyle="solid"
        borderBottomWidth="0"
        borderBottomColor="gray.200"
        position="fixed"
        background={getFocusBarColor({ light: 'gray.50', dark: 'gray.700' })}
        zIndex="docked"
        justify="space-between"
        spacing="4"
      >
        <HStack w="ful" spacing="4" flex="1">
          {shouldShowBackButton && (
            <HStack>
              <TemplateBackButton />

              <Divider h="10" orientation="vertical" />
            </HStack>
          )}

          <TemplateCoverIcon
            editable={isEditable}
            templateId={templateId}
            icon={coverIconQuery.data}
            borderWidth="2px"
            imageHeight={isSmallScreen ? 36 : 46}
            imageWidth={isSmallScreen ? 36 : 46}
            emojiFontSize={isSmallScreen ? 'lg' : '26px'}
            ml={0}
            border="none"
            components={{
              triggerButton: (
                <IconButton
                  aria-label="Add icon"
                  borderRadius="full"
                  variant="outline"
                  icon={<Icon icon="plus" color="gray.200" size="4" />}
                  colorScheme="gray"
                  borderWidth="thin"
                  borderColor="gray.200"
                  _hover={{
                    bgColor: 'gray.800',
                  }}
                />
              ),
            }}
            {...baseCoverIconProps}
          />

          <VStack minWidth="0" alignItems="flex-start" flex="1" spacing={1}>
            <HStack color={getFocusBarColor({ light: 'gray.600', dark: 'white' })} spacing="2" maxW="90%">
              <Icon icon="workflow" variant="fas" size="3" color="indigo.500" />
              {templateQuery.data && <TemplateNameEditor isReadOnly={!isEditable} template={templateQuery.data} />}
            </HStack>

            {!isAnonymousUser && <Breadcrumbs getColor={getFocusBarColor} templateId={templateId} />}
          </VStack>
        </HStack>
        {templateRevisionQuery.data && !isAnonymousUser && (
          <Show breakpoint="(min-width: 850px)">
            {isEditable ? (
              <EditMiddleButtonGroup templateId={templateId} templateRevision={templateRevisionQuery.data} />
            ) : (
              <ViewMiddleButtonGroup templateId={templateId} templateRevision={templateRevisionQuery.data} />
            )}
          </Show>
        )}

        {match({ templateStatus, isAnonymous: isAnonymousUser })
          .with({ templateStatus: TemplateStatus.Active }, () => (
            <>
              <HStack minWidth="0" justifyContent="flex-end" flex="1">
                <EditButtonGroup />
              </HStack>
            </>
          ))
          .with({ templateStatus: TemplateStatus.Archived }, () => (
            <TemplateMenuContext.Provider value={{ setCloseOnBlur, closeOnBlur, templateId, view: 'show' }}>
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<Icon size="4" variant="far" icon="ellipsis-h" />}
                  variant="outline"
                  colorScheme="gray"
                  aria-label="actions"
                />
                <MenuList>
                  <DeleteTemplateButton
                    templateId={templateId}
                    view={'show'}
                    disclosure={{ onOpen: () => setCloseOnBlur(false), onClose: () => setCloseOnBlur(true) }}
                    onDeleted={() => templateMenuDisclosure.onClose()}
                  >
                    {({ isMutating }) => {
                      const icon = isMutating ? (
                        <Icon icon="spinner-third" animation="spin" size="4" variant="far" color="gray.500" />
                      ) : (
                        <Icon icon="trash-alt" size="4" variant="far" color="red.500" />
                      );
                      return (
                        <MenuItem isDisabled={isMutating} color="red.500" icon={icon} iconSpacing="2">
                          Delete
                        </MenuItem>
                      );
                    }}
                  </DeleteTemplateButton>
                </MenuList>
              </Menu>
              <Button variant="secondary" onClick={handleUnarchive}>
                Unarchive
              </Button>
            </TemplateMenuContext.Provider>
          ))
          .otherwise(() => null)}
      </HStack>
    </>
  );
};
