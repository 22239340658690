import * as React from 'react';
import { useFeatureFlag } from 'app/features/feature-flags';
import { ButtonProps, useDisclosure } from 'components/design/next';
import { Muid } from '@process-street/subgrade/core';
import { CustomNotificationsModal } from 'app/pages/templates/_id/components/custom-notifications-modal';
import { useWorkflowState } from 'app/components/focus-bar/workflow/use-workflow-state';
import { useNewestTemplateRevisionQuery } from 'app/pages/pages/_id/edit/page/query';
import { TemplateRevisionWithTemplate } from '@process-street/subgrade/process';
import { useGetConsolidatedTemplatePermissionsQuery } from 'app/features/permissions/query-builder';

export type CustomNotificationsButtonProps = {
  templateId: Muid;
  children: React.ReactElement<ButtonProps>;
};

export const CustomNotificationsButton = ({ templateId, children }: CustomNotificationsButtonProps) => {
  const workflowState = useWorkflowState();
  const editable = workflowState === 'edit';

  const customNotificationsDisclosure = useDisclosure();
  const isCustomNotificationsEnabled = useFeatureFlag('customNotifications');
  const newestTemplateRevisionQuery = useNewestTemplateRevisionQuery({ templateId, editable });

  const templatePermissionsQuery = useGetConsolidatedTemplatePermissionsQuery(templateId);
  const hasPermission = Boolean(templatePermissionsQuery.data?.permissionMap.templateUpdate);

  if (!isCustomNotificationsEnabled) return null;

  if (!React.isValidElement(children) || Array.isArray(children)) {
    throw new Error('CustomNotificationsButton child must be a single clickable element');
  }

  return (
    <>
      {React.cloneElement(children, { onClick: customNotificationsDisclosure.onOpen, isDisabled: !hasPermission })}

      {isCustomNotificationsEnabled && newestTemplateRevisionQuery.data && (
        <CustomNotificationsModal
          {...customNotificationsDisclosure}
          templateRevision={newestTemplateRevisionQuery.data as TemplateRevisionWithTemplate}
          isReadOnly={!editable}
        />
      )}
    </>
  );
};
