import * as React from 'react';
import { Button, Img, Skeleton, Text, VStack } from 'components/design/next';
import {
  FormEditorPageActorSelectors,
  useFormEditorPageActorRef,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useSelector } from '@xstate/react';
import { NewWidgetItem } from 'app/pages/forms/_id/edit/types';
import { WidgetType } from '@process-street/subgrade/process';
import { useStateParam } from 'app/hooks/use-state-param';
import rulesEmptyImg from 'app/images/task-template/approval-rules-empty-img2.svg';
import { TextContent, TextContentActor } from 'app/pages/forms/_id/edit/components/content/text-content';
import { TaskSelect } from './components/task-select';
import { ApprovalTaskList } from './components/approval-task-list';
import _keyBy from 'lodash/keyBy';
import _groupBy from 'lodash/groupBy';

export const ApprovalTask: React.FC = () => {
  const actor = useFormEditorPageActorRef();
  const { send } = actor;
  const taskTemplateGroupId = useStateParam({ key: 'groupId' });
  const widgetsSelector = React.useMemo(
    () => FormEditorPageActorSelectors.getWidgetsForTaskTemplateGroupId(taskTemplateGroupId),
    [taskTemplateGroupId],
  );
  const isLoading = useSelector(actor, FormEditorPageActorSelectors.isLoading);
  const templateRevision = useSelector(actor, FormEditorPageActorSelectors.getTemplateRevision);
  const isReadOnly = useSelector(actor, FormEditorPageActorSelectors.isReadOnly);

  const widgets = useSelector(actor, widgetsSelector);
  const widgetActorMap = useSelector(actor, FormEditorPageActorSelectors.getWidgetActorMap);

  const taskTemplates = useSelector(actor, FormEditorPageActorSelectors.getTaskTemplates);
  const tasksMap = React.useMemo(() => _keyBy(taskTemplates, taskTemplate => taskTemplate.group.id), [taskTemplates]);

  const taskTemplateAssignments = useSelector(actor, FormEditorPageActorSelectors.getTaskAssignments);
  const taskAssignmentsMap = React.useMemo(
    () => _groupBy(taskTemplateAssignments, assignment => assignment.taskTemplate.id),
    [taskTemplateAssignments],
  );

  const dueDateRules = useSelector(actor, FormEditorPageActorSelectors.getDueDateRules);
  const dueDateRulesMap = React.useMemo(
    () => _keyBy(dueDateRules, ruleDefinition => ruleDefinition.targetTaskTemplateGroup?.id ?? ''),
    [dueDateRules],
  );

  const textWidgetActor = React.useMemo(
    () => (widgetActorMap && widgets.length > 0 ? widgetActorMap[widgets[0].header.id] : undefined),
    [widgetActorMap, widgets],
  );

  const approvalRulesList = useSelector(actor, FormEditorPageActorSelectors.getApprovalRules);

  const tasksFromApprovalRule = React.useMemo(
    () =>
      approvalRulesList.filter(
        rule =>
          rule.approvalTaskTemplateGroupId === taskTemplateGroupId &&
          Boolean(tasksMap[rule.subjectTaskTemplateGroupId]),
      ),
    [approvalRulesList, tasksMap, taskTemplateGroupId],
  );

  const isWidgetLoaded = Boolean(textWidgetActor);

  const handleClickAddInstructions = () => {
    const payload: NewWidgetItem = {
      widgetType: WidgetType.Text,
      label: 'Leave a note for the approver (optional):',
    };
    send({ type: 'CREATE_WIDGET', payload });
  };

  const hasRules = tasksFromApprovalRule.length > 0;

  return (
    <VStack
      spacing="0"
      sx={{
        'listStyle': 'none',
        '[data-component="FormFieldLabel"]': { width: 'auto' },
        '& > *:first-of-type': {
          pb: 4,
        },
        '& > *:last-of-type': {
          pb: 0,
        },
        '& > *:not(style)~*:not(style)': {
          py: '4',
        },
      }}
      alignItems="stretch"
      w="full"
      py="8"
      px="0"
    >
      {isLoading ? (
        <LoadingApprovalTaskState />
      ) : (
        <>
          {isWidgetLoaded && (
            <TextContent
              actor={textWidgetActor as TextContentActor}
              placeholder="Leave a note for the approver (optional)"
              isLast={false}
              isFirst
            />
          )}
          <VStack>
            {!isReadOnly && !isWidgetLoaded && hasRules && (
              <Button
                alignSelf="flex-start"
                onClick={handleClickAddInstructions}
                variant="secondary"
                backgroundColor="gray.200"
                color="black"
                fontWeight="bold"
              >
                Add a note for the approver
              </Button>
            )}
            <VStack spacing={4} w="full">
              {!hasRules && (
                <>
                  <Img src={rulesEmptyImg} alt="" width="191px" />
                  {isReadOnly ? (
                    <Text>Edit this workflow to add an Approval step to your tasks</Text>
                  ) : (
                    <Text>Add an Approval step to your tasks</Text>
                  )}
                </>
              )}
              {hasRules && !!templateRevision && (
                <ApprovalTaskList
                  tasksFromApprovalRule={tasksFromApprovalRule}
                  tasksMap={tasksMap}
                  templateRevisionId={templateRevision.id}
                  taskAssignmentsMap={taskAssignmentsMap}
                  dueDateRulesMap={dueDateRulesMap}
                  isReadOnly={isReadOnly}
                />
              )}
              {!isReadOnly && taskTemplateGroupId && !!templateRevision && (
                <>
                  <TaskSelect
                    taskTemplateGroupId={taskTemplateGroupId}
                    tasksFromApprovalRule={tasksFromApprovalRule}
                    tasksMap={tasksMap}
                    templateRevisionId={templateRevision.id}
                  />
                </>
              )}
            </VStack>
          </VStack>
        </>
      )}
    </VStack>
  );
};

const LoadingApprovalTaskState: React.FC = () => {
  const skeletonProps = { startColor: 'gray.100', endColor: 'gray.200' };
  return (
    <VStack w="full" borderLeft="1px solid var(--ps-colors-gray-100)" px={8} gap={4}>
      <VStack w="full" alignItems="stretch" spacing="3">
        <Skeleton maxW="100" w="full" h="4" borderRadius="4px" {...skeletonProps} />
        <Skeleton w="full" h="22" borderRadius="4px" {...skeletonProps} />
        <Skeleton w="full" h="22" borderRadius="4px" {...skeletonProps} />
        <Skeleton w="full" h="22" borderRadius="4px" {...skeletonProps} />
      </VStack>
    </VStack>
  );
};
