import {
  FormFieldWidget,
  MultiChoiceFormFieldWidget,
  SelectFormFieldConfig,
  SelectFormFieldConfigItem,
  SelectFormFieldWidget,
  TaskTemplate,
  Template,
} from '@process-street/subgrade/process';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import _isEqual from 'lodash/isEqual';
import {
  DeleteWidgetByHeaderIdMutation,
  UpdateWidgetMutation,
  WidgetsByTemplateRevisionIdQuery,
} from 'features/widgets/query-builder';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { ActorRefFrom, assign, createMachine, send, sendParent, spawn } from 'xstate';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { ArrayService } from 'services/array-service';
import { Muid, MuidUtils } from '@process-street/subgrade/core';
import { makeSelectItemMachine, ParentEvent as SelectItemChildEvent, SelectItemActorRef } from './select-item-machine';
import { FormFieldLabelActor, makeFormFieldLabelMachine } from '../common/form-field-label';
import { match } from 'ts-pattern';
import { WidgetEvent } from '../../../types';
import { SharedContext } from 'pages/forms/_id/shared';
import { GetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template/query-builder';

type Widget = SelectFormFieldWidget | MultiChoiceFormFieldWidget;

type Context = {
  widget: Widget;
  initialWidget: Widget;
  template: Template;
  itemActorRefs: Record<Muid, SelectItemActorRef>;
  lastCreatedConfigItem?: SelectFormFieldConfigItem;
  labelActor: FormFieldLabelActor<Widget>;
  recentlyMovedFrom?: TaskTemplate;
  inputNode: HTMLElement | null;
  isReadOnly?: boolean;
};

type Event =
  | WidgetEvent<Widget>
  | { type: 'AUTO_FOCUS' }
  | { type: 'ITEM_APPEND' }
  | { type: 'ITEM_REORDER'; items: SelectFormFieldConfigItem[] }
  | { type: 'LINK_UNLINK_DATA_SET'; widget: Widget }
  | SelectItemChildEvent
  // Internal type
  | { type: 'done.invoke.updateWidgetMutation'; data: Widget };

export type SelectFormFieldMachine = ReturnType<typeof makeSelectFormFieldMachine>;
export type SelectFormFieldActor = ActorRefFrom<SelectFormFieldMachine>;

export const makeSelectFormFieldMachine = ({
  widget,
  template,
  sharedContext,
  isReadOnly = false,
}: {
  widget: Widget;
  template: Template;
  sharedContext: SharedContext;
  isReadOnly?: boolean;
}) => {
  const { queryClient, templateId } = sharedContext;

  const cacheSetter = WidgetsByTemplateRevisionIdQuery.makeCacheSetter({
    queryClient,
    templateRevisionId: (widget as FormFieldWidget).templateRevision?.id,
  });

  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient,
    templateId,
  });

  return createMachine(
    {
      id: `select-form-field:${widget.id}`,
      initial: isReadOnly ? 'viewing' : 'idle',
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as Context,
      },
      tsTypes: {} as import('./select-form-field-machine.typegen').Typegen0,
      context: () =>
        ({
          widget,
          initialWidget: widget,
          template,
          itemActorRefs: widget.config.items.reduce((acc, item) => {
            acc[item.id] = spawn(makeSelectItemMachine({ item }), { name: `select-item:${item.id}}` });
            return acc;
          }, {} as Record<Muid, SelectItemActorRef>),
          labelActor: spawn(makeFormFieldLabelMachine<Widget>({ widget, queryClient })),
          recentlyMovedFrom: undefined,
          inputNode: null,
          isReadOnly,
        } as Context),
      states: {
        idle: {
          id: 'idle',
          on: {
            DELETE_WIDGET: { target: 'deleting' },
            MOVE_DOWN: { actions: ['sendMoveDown'] },
            MOVE_UP: { actions: ['sendMoveUp'] },
            DUPLICATE: { actions: ['sendDuplicate'] },
            MOVE_TO_STEP: { actions: ['sendMoveToStep'] },
            MOVED_FROM_STEP: { actions: ['assignRecentlyMovedFrom'] },
            UPDATE_WIDGET_HEADER: { actions: ['assignHeader'] },
            SET_WIDGET_LABEL: { actions: ['assignLabel'] },
            SET_WIDGET_CONFIG: { actions: ['assignConfig', 'sendToConfigItems'] },
            SET_NODE: { actions: ['assignNode'] },
            SCROLL_INTO_VIEW: { actions: ['scrollIntoView'] },
            LINK_UNLINK_DATA_SET: { actions: ['assignLinkedDataSet'] },
            SELECT_TASK_TEMPLATE: { actions: ['sendSelectTaskTemplate'] },
          },
        },
        itemsDebouncing: {
          after: {
            ITEMS_DEBOUNCE_DELAY: 'saving',
          },
        },
        viewing: {},
        editing: {
          id: 'editing',
        },
        saving: {
          id: 'saving',
          invoke: [
            {
              id: 'updateWidgetMutation',
              src: 'updateWidgetMutation',
              onDone: {
                target: 'idle',
                actions: ['assignWidget', 'sendUpdateMergeTags', 'assignInitialWidget', 'sendUpdateDone'],
              },
              onError: {
                target: 'error',
                actions: ['resetWidget', 'sendUpdateError'],
              },
            },
          ],
        },
        error: {},
        deleting: {
          invoke: [
            {
              id: 'deleteWidgetMutation',
              src: 'deleteWidgetMutation',
              onDone: {
                target: 'deleted',
              },
              onError: {
                target: 'idle',
              },
            },
          ],
        },
        deleted: {
          type: 'final',
        },
      },
      on: {
        ITEM_APPEND: { target: '.itemsDebouncing', actions: ['appendItem', 'sendAutoFocusToLastItem'] },
        ITEM_REORDER: { target: 'saving', actions: 'assignWidgetItems' },
        ITEM_REMOVE: { target: '.itemsDebouncing', actions: ['sendAutoFocusToPreviousItem', 'removeItem'] },
        ITEM_INSERT: { target: '.itemsDebouncing', actions: ['insertItem', 'sendAutoFocusToNewItem'] },
        ITEM_UPDATE: { target: '.itemsDebouncing', actions: 'updateItem' },
        ITEM_MOVE: { target: '.itemsDebouncing', actions: 'moveItemUpOrDown' },
        UPDATE_WIDGET: {
          target: 'saving',
          actions: ['assignWidget'],
        },
        AUTO_FOCUS: {
          actions: ['sendAutoFocus'],
        },
      },
    },
    {
      actions: {
        assignWidget: assign({
          widget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET' }, ({ widget }) => widget)
              .with({ type: 'done.invoke.updateWidgetMutation' }, ({ data }) => data)
              .otherwise(() => ctx.widget),
        }),
        assignLinkedDataSet: assign({
          widget: (ctx, e) => {
            return { ...ctx.widget, label: e.widget.label, config: { ...e.widget.config } };
          },
        }),
        assignInitialWidget: assign({
          initialWidget: (_, e) => e.data,
        }),
        sendUpdateMergeTags: sendParent(ctx => ({
          type: 'UPDATE_MERGE_TAGS_REFERENCES',
          widget: ctx.widget,
          oldKey: ctx.initialWidget.key,
        })),
        resetWidget: assign({
          widget: context => context.initialWidget,
        }),

        sendMoveUp: sendParent(ctx => ({ type: 'MOVE_WIDGET', widget: ctx.widget, direction: 'up' })),
        sendMoveDown: sendParent(ctx => ({ type: 'MOVE_WIDGET', widget: ctx.widget, direction: 'down' })),
        sendDuplicate: sendParent(ctx => ({ type: 'DUPLICATE_WIDGET', widget: ctx.widget })),

        updateItem: assign({
          widget: (context, e) => {
            const { widget } = context;

            const newItems = widget.config.items.map(item => (item.id === e.item.id ? e.item : item));
            return { ...widget, config: { ...widget.config, items: newItems } };
          },
        }),

        insertItem: assign((context, event) => {
          const { widget } = context;

          const afterIndex = widget.config.items.findIndex(item => item.id === event.item.id);
          const newItem = { id: MuidUtils.randomMuid(), name: '' };

          const newItems = widget.config.items.flatMap((item, index) => {
            if (index !== afterIndex) return item;
            return [item, newItem];
          });

          const newActor = spawn(makeSelectItemMachine({ item: newItem }), { name: `select-item:${newItem.id}}` });

          return {
            widget: { ...widget, config: { ...widget.config, items: newItems } },
            itemActorRefs: { ...context.itemActorRefs, [newItem.id]: newActor },
          };
        }),

        sendAutoFocusToNewItem: send(
          { type: 'AUTO_FOCUS' },
          {
            to: (ctx, event) => {
              const afterIndex = ctx.widget.config.items.findIndex(item => item.id === event.item.id);
              const newItem = ctx.widget.config.items[afterIndex + 1];
              return ctx.itemActorRefs[newItem.id];
            },
          },
        ),

        appendItem: assign((context, _e) => {
          const { widget } = context;

          const item = { id: MuidUtils.randomMuid(), name: '' };
          const newItems = [...widget.config.items, item];
          return {
            widget: { ...widget, config: { ...widget.config, items: newItems } },
            itemActorRefs: {
              ...context.itemActorRefs,
              [item.id]: spawn(makeSelectItemMachine({ item }), { name: `select-item:${item.id}` }),
            },
          };
        }),

        sendAutoFocusToLastItem: send(
          { type: 'AUTO_FOCUS' },
          {
            to: (ctx, _event) => {
              const newItem = ctx.widget.config.items[ctx.widget.config.items.length - 1];
              return ctx.itemActorRefs[newItem.id];
            },
          },
        ),

        removeItem: assign({
          widget: (context, e) => {
            return {
              ...context.widget,
              config: {
                ...context.widget.config,
                items: context.widget.config.items.filter(item => item.id !== e.item.id),
              },
            };
          },
          itemActorRefs: (context, e) => {
            const { [e.item.id]: actor, ...rest } = context.itemActorRefs;
            actor?.stop?.();
            return rest;
          },
        }),

        sendAutoFocusToPreviousItem: send(
          { type: 'AUTO_FOCUS' },
          {
            to: (ctx, event) => {
              const index = ctx.widget.config.items.findIndex(item => item.id === event.item.id);
              const previousItem = ctx.widget.config.items[index - 1];
              if (!previousItem) return '';
              const actor = ctx.itemActorRefs[previousItem.id];
              return actor;
            },
          },
        ),

        assignWidgetItems: assign({
          widget: ({ widget }, e) => {
            return { ...widget, config: { ...widget.config, items: e.items } };
          },
        }),

        moveItemUpOrDown: assign({
          widget: ({ widget }, e) => {
            const index = widget.config.items.findIndex(item => item.id === e.item.id);
            const newItems = ArrayService.movePure(
              [...widget.config.items],
              index,
              e.direction === 'up' ? index - 1 : index + 1,
            );
            return { ...widget, config: { ...widget.config, items: newItems.filter(Boolean) } };
          },
        }),

        sendAutoFocus: send({ type: 'AUTO_FOCUS' }, { to: ctx => ctx.labelActor }),
        sendUpdateDone: send(ctx => ({ type: 'UPDATE_DONE', data: ctx.widget }), { to: ctx => ctx.labelActor }),
        sendUpdateError: send({ type: 'UPDATE_ERROR' }, { to: ctx => ctx.labelActor }),
        sendMoveToStep: sendParent((ctx, e) => ({
          type: 'MOVE_WIDGET_TO_STEP',
          widget: ctx.widget,
          from: e.from,
          to: e.to,
        })),
        assignHeader: assign({
          widget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET_HEADER' }, ({ header }) => ({
                ...ctx.widget,
                header: {
                  ...ctx.widget.header,
                  ...{ ...(header as SelectFormFieldWidget['header']) },
                },
              }))
              .otherwise(() => ctx.widget),
        }),
        assignLabel: send((_ctx, e) => ({ type: 'CHANGE', value: e.label }), { to: ctx => ctx.labelActor }),
        assignConfig: assign({
          itemActorRefs: (ctx, evt) => {
            const newRefs = { ...ctx.itemActorRefs };
            const newItems = (evt.config as SelectFormFieldConfig).items || [];

            newItems.forEach(item => {
              if (!newRefs[item.id]) {
                newRefs[item.id] = spawn(makeSelectItemMachine({ item }), { name: `select-item:${item.id}` });
              }
            });

            return newRefs;
          },
          widget: (ctx, evt) => ({
            ...ctx.widget,
            config: {
              ...ctx.widget.config,
              ...evt.config,
            },
          }),
        }),
        sendToConfigItems: (ctx, evt) => {
          Object.values(ctx.itemActorRefs).forEach(actor => {
            const actorItemId = actor.getSnapshot()?.context.item.id;
            if (actorItemId) {
              const configValue = (evt.config as SelectFormFieldConfig).items.find(config => config.id === actorItemId);
              if (configValue) {
                actor.send({ type: 'CHANGE_INTERNAL', value: configValue.name });
              }
            }
          });
        },
        assignNode: assign({ inputNode: (_, evt) => evt.node }),
        scrollIntoView: ctx => {
          ctx.inputNode?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        },
        assignRecentlyMovedFrom: assign({
          recentlyMovedFrom: (_ctx, e) =>
            match(e)
              .with({ type: 'MOVED_FROM_STEP' }, ({ from }) => ({
                ...from,
              }))
              .otherwise(() => undefined),
        }),
        sendSelectTaskTemplate: sendParent((_ctx, evt) => ({
          type: 'SELECT_TASK_TEMPLATE',
          taskTemplate: evt.taskTemplate,
        })),
      },
      services: {
        updateWidgetMutation: async (context, e) => {
          const widget = match(e)
            .with({ type: 'UPDATE_WIDGET' }, ({ widget }) => widget)
            .otherwise(() => context.widget);
          if (_isEqual(context.initialWidget, widget)) return Promise.resolve(context.widget);

          return makeMutation(queryClient, {
            mutationKey: UpdateWidgetMutation.getKey(),
            mutationFn: () => UpdateWidgetMutation.mutationFn<Widget>(context.widget),
            onSuccess: widget => {
              cacheSetter.update(widget);
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
          }).execute();
        },
        deleteWidgetMutation: async context => {
          return makeMutation(queryClient, {
            mutationKey: DeleteWidgetByHeaderIdMutation.getKey(),
            mutationFn: () => DeleteWidgetByHeaderIdMutation.mutationFn(context.widget.header.id),
            onSuccess: () => {
              cacheSetter.delete(context.widget);
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
            onError: () => {
              ToastServiceImpl.openToast({
                status: 'error',
                title: `We're having problems deleting the widget`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
            },
          }).execute();
        },
      },
      delays: {
        ITEMS_DEBOUNCE_DELAY: 500,
      },
    },
  );
};
