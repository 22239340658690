import {
  Box,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from 'components/design/next';
import { ChecklistRuleDefinition } from '@process-street/subgrade/conditional-logic';
import { isForm, TaskTemplate, Template, TemplateType, Widget } from '@process-street/subgrade/process';
import { NodeInfoMap, SelectorHelper } from 'directives/rules/template/task-templates-selector/selector-helper';
import { GetAllRulesByTemplateRevisionIdQuery } from 'features/conditional-logic/query-builder';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import * as yup from 'yup';
import { ModalBody } from './modal-body';
import { useConditionalLogicModalStore } from './store';
import { useSaveConditionalLogicMutation } from './use-save-conditional-logic';
import { ConditionalLogicModalFormValues, getConditionalLogicDelta } from './get-conditional-logic-delta';
import { PreventLostWorkModal } from '../prevent-lost-work-modal';
import { useSyncTemplateEditor } from './use-sync-template-editor';
import { useIsLoadingRules } from './use-rules-loading';
import { useFeatureFlag } from 'features/feature-flags';
import { ErrorBoundary } from 'react-error-boundary';
import { ConditionalLogicErrorFallback } from './error-fallback';
import { ConditionalLogicUtils } from 'features/conditional-logic/utils/conditional-logic-utils';
import { getRuleSchema } from 'features/conditional-logic/schema';
import { TemplateTypeProvider } from 'utils/template/template-type-context';
import { CONDITIONAL_LOGIC_MODAL_EVENT, ConditionalLogicModalEvent } from './conditional-logic-modal-event';
import { getStepName } from 'pages/forms/_id/edit/helpers/steps';
import { EventEmitterService } from '@process-street/subgrade/util/event-emitter-service';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';

export type ConditionalLogicModalProps = {
  templateRevisionId: string;
  template?: Template;
  isOpen: boolean;
  onClose: () => void;
};

const ConditionalLogicModalContent: React.FC<React.PropsWithChildren<ConditionalLogicModalProps>> = ({
  templateRevisionId,
  template,
  ...modalProps
}) => {
  const { normalizedData, dispatch } = useConditionalLogicModalStore();

  const formikRef = React.useRef<FormikProps<ConditionalLogicModalFormValues> | null>(null);
  const discardChangesDisclosure = useDisclosure();
  const shouldAutoHide = useFeatureFlag('autoHideByDefault');

  const rulesQuery = GetAllRulesByTemplateRevisionIdQuery.useQuery(
    { templateRevisionId },
    { enabled: modalProps.isOpen },
  );
  const widgetsQuery = useWidgetsByTemplateRevisionIdQuery(templateRevisionId, { enabled: modalProps.isOpen });
  const taskTemplatesQuery = useTaskTemplatesByTemplateRevisionIdQuery(
    { templateRevisionId },
    {
      enabled: modalProps.isOpen && Boolean(template),
      select: taskTemplates => {
        return isForm(template!)
          ? taskTemplates.map((taskTemplate, i) => ({
              ...taskTemplate,
              name: getStepName({ taskTemplate, number: i + 1 }),
            }))
          : taskTemplates;
      },
    },
  );

  const isLoadingRules = useIsLoadingRules({ query: rulesQuery, isModalOpen: modalProps.isOpen });
  const saveConditionalLogicMutation = useSaveConditionalLogicMutation({
    templateRevisionId,
    templateId: template?.id,
    closeModal: modalProps.onClose,
  });

  const [isLoadingInitialData, setIsLoadingInitialData] = React.useState(
    widgetsQuery.isLoading || taskTemplatesQuery.isLoading || isLoadingRules,
  );

  const initialValues = React.useMemo(() => {
    const initialNodeInfoMap = taskTemplatesQuery.data
      ? getInitialNodeInfoMap({
          widgets: widgetsQuery.data ?? [],
          taskTemplates: taskTemplatesQuery.data,
        })
      : {};

    return { rules: rulesQuery.data?.definitions ?? [], nodeInfoMap: initialNodeInfoMap };
  }, [rulesQuery.data?.definitions, widgetsQuery.data, taskTemplatesQuery.data]);

  const taskTemplates = React.useMemo(() => taskTemplatesQuery.data ?? [], [taskTemplatesQuery.data]);
  const widgets = React.useMemo(() => widgetsQuery.data ?? [], [widgetsQuery.data]);

  const handleClose = () => {
    const values = formikRef.current?.values;

    if (!values) {
      dispatch({ type: 'RESET_SELECTED_VALUES' });

      return modalProps.onClose();
    }

    const delta = getConditionalLogicDelta({
      initialRules: rulesQuery.data?.definitions ?? [],
      taskTemplates: taskTemplatesQuery.data ?? [],
      widgets: widgetsQuery.data ?? [],
      values,
      normalizedData,
      shouldAutoHide,
    });

    const hasChanges =
      delta.rules.newRules.length ||
      delta.rules.updatedRules.length ||
      delta.rules.deletedRuleIds.length ||
      delta.widgetsHiddenByDefault.length ||
      delta.taskTemplatesHiddenByDefault.length;

    if (hasChanges) {
      discardChangesDisclosure.onOpen();
    } else {
      dispatch({ type: 'RESET_SELECTED_VALUES' });
      modalProps.onClose();
    }
  };

  const handleSubmit = (values: { rules: ChecklistRuleDefinition[]; nodeInfoMap: NodeInfoMap }) => {
    const delta = getConditionalLogicDelta({
      initialRules: rulesQuery.data?.definitions ?? [],
      taskTemplates: taskTemplatesQuery.data ?? [],
      widgets: widgetsQuery.data ?? [],
      values,
      normalizedData,
      shouldAutoHide,
    });

    saveConditionalLogicMutation.mutate({
      templateRevisionId,
      delta,
    });
  };

  useSyncTemplateEditor({ templateRevisionId, isModalOpen: modalProps.isOpen });

  // We are using `useEffect` + `useState` instead of just a normal `const` to hold this value because we need
  // to skip one rendering cycle before we set the state to loaded so we can sync the context values before rendering
  // the list of rules. If we render the list of rules before everything is set, it might cause bugs due to racing conditions.
  React.useEffect(() => {
    setIsLoadingInitialData(widgetsQuery.isLoading || taskTemplatesQuery.isLoading || isLoadingRules);
  }, [isLoadingRules, widgetsQuery.isLoading, taskTemplatesQuery.isLoading]);

  const formSchema = React.useMemo(
    () =>
      yup.object().shape({
        rules: yup.array().of(getRuleSchema(normalizedData)),
      }),
    [normalizedData],
  );
  const isReactWFEditorEnabled = useFeatureFlag('reactWorkflowEditor');

  const modalContent = (
    <ModalContent
      mt={{ base: '0', md: '10' }}
      minH="90vh"
      maxH={{ base: '100vh', md: '90vh' }}
      maxW={{ base: '99vw', md: '90vw' }}
      aria-busy={isLoadingInitialData}
    >
      <ModalHeader
        pt="8"
        pb="4"
        as={Stack}
        spacing={2}
        direction={['column', 'row']}
        borderBottom="1px"
        borderStyle="solid"
        borderColor="gray.200"
      >
        <HStack spacing="2">
          <Box as="i" className="far fa-random fa-fw" color="gray.500" fontSize="md" />

          <Text fontWeight="semibold" fontSize="lg" color="gray.700">
            Conditional Logic
          </Text>
        </HStack>

        <Text fontSize="lg" fontWeight="normal" color="gray.500">
          {template?.name}
        </Text>
      </ModalHeader>
      <ModalCloseButton />
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize
      >
        <ModalBody
          initialRules={rulesQuery.data?.definitions ?? []}
          taskTemplates={taskTemplates}
          widgets={widgets}
          onCancel={handleClose}
          isSaving={saveConditionalLogicMutation.isLoading}
          isLoading={isLoadingInitialData}
        />
      </Formik>
    </ModalContent>
  );
  return (
    <>
      <Modal {...modalProps} onClose={handleClose} size="6xl" scrollBehavior="inside" returnFocusOnClose={false}>
        <ModalOverlay />

        {isReactWFEditorEnabled ? <ThemeProvider2024>{modalContent}</ThemeProvider2024> : <>{modalContent}</>}
      </Modal>

      <PreventLostWorkModal
        isOpen={discardChangesDisclosure.isOpen}
        onClose={discardChangesDisclosure.onClose}
        onDiscard={() => {
          modalProps.onClose();
          discardChangesDisclosure.onClose();
          dispatch({ type: 'RESET_SELECTED_VALUES' });
        }}
      />
    </>
  );
};

export const ConditionalLogicModal: React.FC<ConditionalLogicModalProps> = props => {
  const { dispatch } = useConditionalLogicModalStore();

  React.useEffect(
    () =>
      EventEmitterService.on<ConditionalLogicModalEvent>(CONDITIONAL_LOGIC_MODAL_EVENT.OPEN, args => {
        const { taskTemplate, widget } = args;
        if (taskTemplate) {
          // Fixes race condition with the Condition component that needs this set first.
          setTimeout(() => {
            dispatch({ type: 'SET_SELECTED_TASK', payload: taskTemplate });
          });
        }
        if (widget) {
          // Fixes race condition with the Condition component that needs this value set first.
          setTimeout(() => dispatch({ type: 'SET_SELECTED_WIDGET', payload: widget }));
        }
      }),
    [dispatch],
  );
  React.useEffect(
    () =>
      EventEmitterService.on(CONDITIONAL_LOGIC_MODAL_EVENT.CLOSE, () => {
        dispatch({ type: 'RESET_SELECTED_VALUES' });
      }),
    [dispatch],
  );

  return (
    <ErrorBoundary FallbackComponent={ConditionalLogicErrorFallback}>
      <TemplateTypeProvider templateType={props.template?.templateType ?? TemplateType.Playbook}>
        <ConditionalLogicModalContent {...props} />
      </TemplateTypeProvider>
    </ErrorBoundary>
  );
};

const getInitialNodeInfoMap = ({ taskTemplates, widgets }: { taskTemplates: TaskTemplate[]; widgets: Widget[] }) => {
  const nodesList = SelectorHelper.createNodes(taskTemplates, widgets);

  const hiddenWidgets = widgets.filter(w => w.header.hiddenByDefault);
  const hiddenTaskTemplates = taskTemplates.filter(tt => tt.hiddenByDefault);

  const initialNodeInfoMap = ConditionalLogicUtils.getInitialNodeInfoMap(nodesList, hiddenWidgets, hiddenTaskTemplates);

  return initialNodeInfoMap;
};
