import * as React from 'react';

import { useSelector } from '@xstate/react';
import { TaskListItem, TaskListItemProps } from '../task-list-item';
import { DueDateRuleDefinition, TemplateTaskAssignment } from '@process-street/subgrade/process';
import _groupBy from 'lodash/groupBy';
import _keyBy from 'lodash/keyBy';
import _isEqual from 'lodash/isEqual';
import { TaskTemplateListActorSelectors } from './task-template-list-machine';
import { Option } from 'space-monad';
import _map from 'lodash/fp/map';
import { OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { useTaskTemplateListActorRef } from '../../hooks/use-task-templates-list-actor';
import { Dictionary } from 'lodash';
import { TaskAssignmentRule } from '@process-street/subgrade/role-assignment';

type TaskListItemWrapperProps = Omit<
  TaskListItemProps,
  'assignmentRules' | 'organizationMemberships' | 'isSelected' | 'isMultiSelecting'
> & {
  isSelected: boolean;
  dueDateRulesMap: Dictionary<DueDateRuleDefinition>;
  taskAssignmentsMap: Dictionary<TemplateTaskAssignment[]>;
  organizationMembershipsMap: Dictionary<OrganizationMembershipWithUser>;
  taskAssignmentRulesMap: Dictionary<TaskAssignmentRule[]>;
};

export const TaskListItemWrapper = React.memo(function TaskListItemWrapper({
  dueDateRulesMap,
  taskAssignmentsMap,
  organizationMembershipsMap,
  taskAssignmentRulesMap,
  ...props
}: TaskListItemWrapperProps) {
  const taskTemplateListActor = useTaskTemplateListActorRef();

  const dueDateRuleDefinition = React.useMemo(
    () => dueDateRulesMap[props.taskTemplate.group.id],
    [dueDateRulesMap, props.taskTemplate.group.id],
  );

  const organizationMemberships = React.useMemo(
    () =>
      Option(taskAssignmentsMap[props.taskTemplate.id])
        .map(_map(taskAssignment => organizationMembershipsMap[taskAssignment.organizationMembership.id]))
        .getOrElse([]),
    [organizationMembershipsMap, props.taskTemplate.id, taskAssignmentsMap],
  );

  const assignmentRules = React.useMemo(() => {
    return taskAssignmentRulesMap[props.taskTemplate.group.id] ?? [];
  }, [props.taskTemplate.group.id, taskAssignmentRulesMap]);

  const isMultiSelecting = useSelector(taskTemplateListActor, TaskTemplateListActorSelectors.isMultiSelecting);

  return (
    <TaskListItem
      assignmentRules={assignmentRules}
      dueDateRuleDefinition={dueDateRuleDefinition}
      organizationMemberships={organizationMemberships}
      isMultiSelecting={isMultiSelecting}
      {...props}
    />
  );
});
