import * as React from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  HStack,
  Icon,
  IconButton,
  MenuButton,
  Show,
  Tooltip,
  useDisclosure,
} from 'components/design/next';

import { TemplateMenu, TemplateMenuContext } from 'features/template/components/template-menu';

import { useGetTemplateQuery } from 'features/template/query-builder';
import { DiscardTemplateButton } from 'features/template/components/template-menu/discard-template-button';
import { noop, queryString } from '@process-street/subgrade/util';
import { useTemplateSettingsModalContext } from 'pages/templates/_id/components/template-settings-modal/template-settings-modal-context';
import { PublishDraftMutation } from 'features/template-revisions/query-builder';
import { useGetChecklistRevisionsByTemplateIdQuery } from 'features/checklist-revisions/query-builder';
import { useDraftStatus } from '../../../edit/page/components/draft-status-indicator/use-draft-status';
import { DraftStatusIndicator } from 'app/components/focus-bar/workflow/draft-status-indicator';
import { FocusBarIconButton } from 'app/components/focus-bar/workflow/right-button-group/edit/focus-bar-icon-button';
import { useNavigate } from '@process-street/adapters/navigation';
import { useGetConsolidatedTemplatePermissionsQuery } from 'app/features/permissions/query-builder';
import { TemplateShareButton } from 'app/directives/template-share/template-share-button';
import { TemplateMemberList } from 'app/components/template/membership/components/TemplateMemberList/TemplateMemberList';
import { TemplateStatus } from '@process-street/subgrade/process';
import { useIsPrivateTemplate } from 'app/hooks/use-is-private-template';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'app/reducers/session/session.selectors';
import { useIsEditable } from 'app/features/rich-text';

export const SHOW_WORKFLOW_SETUP_MODAL_FOR = 'showWorkflowSetupModalFor';

const buttonStyles = { bgColor: 'gray.200', color: 'gray.700', textDecor: 'none' };

const focusButtonViewStyles = {
  borderColor: 'gray.300',
  _hover: { bgColor: 'gray.100' },
  _active: { bgColor: 'gray.100' },
  _focus: { bgColor: 'gray.100' },
};

export const EditButtonGroup: React.FC<React.PropsWithChildren<ButtonGroupProps>> = props => {
  const templateMenuDisclosure = useDisclosure();
  const migrationDisclosure = useDisclosure();
  const isEditable = useIsEditable();
  const { templateId, templateRevisionId } = useTemplateSettingsModalContext();
  if (!templateRevisionId) {
    throw new Error('Expecting template revision ID for this component.');
  }

  const draftStatus = useDraftStatus();

  const templateQuery = useGetTemplateQuery({ templateId });
  const template = templateQuery.data;
  const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(templateId);
  const sharingIsEnabled =
    !!permissionMap &&
    (permissionMap.templatePermitsManage ||
      permissionMap.templateShareLevelUpdate ||
      permissionMap.templateShareLinkUpdate);

  const isPrivate = useIsPrivateTemplate(templateId);
  const isGuest = useSelector(SessionSelector.isUserGuestOfSelectedOrganization);

  const isActiveTemplate = template?.status == TemplateStatus.Active;
  const isTemplateMembershipViewable = !(isPrivate || isGuest) && isActiveTemplate;

  const publishDraftMutation = PublishDraftMutation.useMutation();
  const canPublish = !!template && draftStatus[0] === 'saved' && publishDraftMutation.isIdle;

  const navigate = useNavigate();
  const checklistRevisionsQuery = useGetChecklistRevisionsByTemplateIdQuery({ templateId });

  const publish = async () => {
    return checklistRevisionsQuery.refetch().then(res => {
      if (res.data?.length === 0) {
        return publishDraftMutation.mutateAsync({ tmplRevId: templateRevisionId }).then(() => {
          navigate({
            pathname: 'pageViewV2',
            search: queryString.stringify({ id: templateId }),
          });
        });
      }

      migrationDisclosure.onOpen();

      return;
    });
  };

  const textColor = isEditable ? 'white' : 'gray.500';

  return (
    <ButtonGroup spacing="2" alignItems="center" {...props}>
      <HStack color={textColor} sx={{ svg: { color: textColor } }}>
        {isEditable && (
          <>
            <DraftStatusIndicator />
            <Box as="span" display="flex">
              <TemplateMenuContext.Provider
                value={{ templateId, view: 'show', closeOnBlur: false, setCloseOnBlur: noop }}
              >
                <Tooltip label="Discard">
                  <DiscardTemplateButton>
                    <IconButton
                      aria-label="discard"
                      minW={6}
                      height={6}
                      icon={<Icon icon="trash" variant="far" size="4" />}
                      variant="link"
                      color={textColor}
                      _active={buttonStyles}
                      _hover={buttonStyles}
                      _focus={buttonStyles}
                    />
                  </DiscardTemplateButton>
                </Tooltip>
              </TemplateMenuContext.Provider>
            </Box>
          </>
        )}
        <Show breakpoint="(min-width: 950px)">
          {isTemplateMembershipViewable && <TemplateMemberList templateId={templateId} />}
        </Show>
      </HStack>

      <TemplateShareButton
        templateId={templateId}
        isDisabled={!sharingIsEnabled}
        modalOptions={{ hideTabs: true, tab: 'sharelink' }}
      >
        <FocusBarIconButton
          aria-label="Share link"
          icon={<Icon color={textColor} icon="share-alt" variant="far" size="4" />}
          tooltipText="Share link"
          sx={isEditable ? {} : focusButtonViewStyles}
        />
      </TemplateShareButton>

      <TemplateMenu
        autoSelect={false}
        mode="view"
        {...templateMenuDisclosure}
        placement="bottom-start"
        menuButton={
          <MenuButton
            as={FocusBarIconButton}
            aria-label="actions"
            sx={isEditable ? {} : focusButtonViewStyles}
            icon={<Icon size="4" variant="far" icon="ellipsis-h" color={textColor} />}
          />
        }
      />

      {isEditable && (
        <Show breakpoint="(min-width: 1100px)">
          <Button
            variant="cta"
            onClick={publish}
            isDisabled={!canPublish}
            isLoading={publishDraftMutation.isLoading || publishDraftMutation.isSuccess}
            color="gray.700"
            aria-label="publish changes"
          >
            Publish
          </Button>
        </Show>
      )}
    </ButtonGroup>
  );
};
