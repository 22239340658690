import { TemplateOverview } from '@process-street/subgrade/process/template-overview-model';
import * as React from 'react';
import { useNgStateModalControls } from 'components/ng-state-modal-controls';
import { Muid } from '@process-street/subgrade/core';
import { RunChecklist } from 'app/components/run-checklist/components/RunChecklist/run-checklist';
import { AppModalName, AppModalQueryParam } from 'app/app.constants';
import { useStateParam } from 'hooks/use-state-param';
import { useCanRunWorkflows } from 'hooks/use-can-run-workflows/use-can-run-workflows';

const ModalKey = AppModalName.RunChecklist;

export const RunChecklistModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [selectedTemplateOverview, setSelectedTemplateOverview] = React.useState<TemplateOverview | undefined>(
    undefined,
  );

  const handleClearSelectedTemplate = () => {
    setSelectedTemplateOverview(undefined);
  };

  const { isOpen, onClose } = useNgStateModalControls(ModalKey);
  const canRunWorkflows = useCanRunWorkflows();
  const isDisabled = !canRunWorkflows;

  React.useEffect(() => {
    if (isDisabled && isOpen) {
      onClose();
    }
  }, [isDisabled, isOpen, onClose]);

  const modalTemplateId = useStateParam<Muid>({ key: AppModalQueryParam.ModalTemplateId });
  const templateId = modalTemplateId ?? selectedTemplateOverview?.id;
  const handleRunChecklistCancel = () => {
    if (modalTemplateId) {
      onClose();
    } else {
      handleClearSelectedTemplate();
    }
  };

  return templateId ? (
    <RunChecklist
      isOpen={isOpen}
      onChecklistCreate={handleClearSelectedTemplate}
      onClose={onClose}
      templateId={templateId}
      onCancel={handleRunChecklistCancel}
    />
  ) : null;
};
