import * as React from 'react';
import { UIActorProvider, useSharedContext } from 'app/pages/forms/_id/shared';
import {
  FormEditorPageActorRefProvider,
  FormEditorPageActorSelectors,
  makeFormEditorPageMachine,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useInterpret, useSelector } from '@xstate/react';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ViewPage } from './view-page';
import { withAnonymousAuth } from 'app/pages/workflows/_id/view-v2/components/setup-anonymous-auth';
import { IsEditableProvider } from 'app/features/rich-text';
import { TemplateSettingsModalProvider } from 'app/pages/templates/_id/components/template-settings-modal/template-settings-modal-context';
import _isEqual from 'lodash/isEqual';

export const ViewPageWrapper = withAnonymousAuth(() => {
  const sharedContext = useSharedContext();
  const machine = React.useRef(makeFormEditorPageMachine({ sharedContext, isReadOnly: true })).current;
  const actor = useInterpret(machine);
  const uiActorRef = useSelector(actor, FormEditorPageActorSelectors.getUIActorRef);

  const template = useSelector(actor, FormEditorPageActorSelectors.getTemplate);

  const publishedTemplateRevision = useSelector(actor, FormEditorPageActorSelectors.getPublishedTemplateRevision);

  if (!template || !publishedTemplateRevision) return null;

  return (
    <FormEditorPageActorRefProvider actor={actor}>
      <ThemeProvider2024>
        <UIActorProvider uiActorRef={uiActorRef}>
          <DndProvider backend={HTML5Backend}>
            <IsEditableProvider isEditable={false}>
              <TemplateSettingsModalProvider templateId={template.id} templateRevisionId={publishedTemplateRevision.id}>
                <ViewPage template={template} />
              </TemplateSettingsModalProvider>
            </IsEditableProvider>
          </DndProvider>
        </UIActorProvider>
      </ThemeProvider2024>
    </FormEditorPageActorRefProvider>
  );
});
