import { useMatch } from '@process-street/adapters/navigation';
import { Muid } from '@process-street/subgrade/core';
import { RunWorkflowButton } from 'app/components/focus-bar/workflow/run-workflow-button';
import { PaywallProvider } from 'app/components/paywalls';
import { useGetConsolidatedTemplatePermissionsQuery } from 'app/features/permissions/query-builder';
import { Box, Button, Text, useToast } from 'components/design/next';
import * as React from 'react';

export type ViewModeInteractionWrapperProps = React.PropsWithChildren<{ templateId: Muid }>;

export const ViewModeInteractionWrapper = ({ children, templateId }: ViewModeInteractionWrapperProps) => {
  const toast = useToast();
  const isTemplateViewV2 = useMatch('templateViewV2');

  const templatePermissionsQuery = useGetConsolidatedTemplatePermissionsQuery(templateId);
  const canRun = Boolean(templatePermissionsQuery.data?.permissionMap.checklistCreate);

  if (!isTemplateViewV2 || !canRun) return <>{children}</>;

  const handleClick = () => {
    toast({
      title: 'View mode',
      description: (
        <PaywallProvider>
          <Text>
            <RunWorkflowButton>
              <Button
                fontWeight="normal"
                colorScheme="gray"
                textDecoration="underline"
                variant="link"
                minW="unset"
                mr={1}
                onClick={() => toast.closeAll()}
              >
                Run
              </Button>
            </RunWorkflowButton>
            your workflow to enter data into form fields
          </Text>
        </PaywallProvider>
      ),
      status: 'info',
    });
  };

  return (
    <Box w="full" position="relative" onClick={handleClick}>
      {children}
      <Box position="absolute" left="0" right="0" top="0" bottom="0" />
    </Box>
  );
};
