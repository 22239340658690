import * as React from 'react';
import { Box } from 'components/design/next';
import { TableContentActor } from './table-content-widget-machine';
import { useActor } from '@xstate/react';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { ContentFieldRecentlyMovedIndicator } from '../common/content-field-recently-moved-indicator';
import { useContentTableData } from 'app/pages/forms/_id/edit/components/content/table-content-widget/hooks/use-content-table-data';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid-no-native-widgets.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import 'app/styles/ag-grid/ag-theme-standard.scss';
import { useAgGridColDefs } from 'pages/forms/_id/shared/hooks/use-ag-grid-col-defs';
import {
  CellValueChangedEvent,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  MenuItemDef,
} from '@ag-grid-community/core';
import { TableFieldValue } from '@process-street/subgrade/process/field-values/table-field-value';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ColumnHeader, ColumnHeaderProps } from '../../form-fields/table-form-field/column-header';
import { TableContentUtils } from './utils';
import { AddNewRowRenderer } from './components/add-new-row-renderer';
import { TableContentContextProvider } from './context';

export const DEFAULT_TABLE_CONTENT =
  "'<table><thead><tr><th><p><strong></strong></p></th><th><p><strong></strong></p></th></tr></thead><tbody><tr><td><p></p></td><td><p></p></td></tr><tr><td><p></p></td><td><p></p></td></tr></tbody></table>'";
export interface TableContentWidgetProps {
  actor: TableContentActor;
  isFirst?: boolean;
  isLast?: boolean;
}

export const TableContentWidget: React.FC<React.PropsWithChildren<TableContentWidgetProps>> = ({
  actor,
  isFirst = false,
  isLast = false,
}) => {
  const [state, send] = useActor(actor);
  const { widget, recentlyMovedFrom, isReadOnly } = state.context;

  const initialValue = state.context.widget.content;
  const { tableData, setTableData } = useContentTableData(initialValue ?? '', !isReadOnly);
  const { headers, rows } = tableData;
  const handleRenameColumn = React.useCallback(
    (id: string, columnName: string) => {
      const newHeaders = TableContentUtils.handleRenameColumn(headers, id, columnName);
      const htmlValue = TableContentUtils.generateHtml({ headers: newHeaders, rows });
      send({ type: 'CHANGE', content: htmlValue });
      setTableData({ headers: newHeaders, rows });
    },
    [send, headers, setTableData, rows],
  );
  const colDefs = useAgGridColDefs<TableFieldValue.Row>(headers, {
    editable: !isReadOnly,
    suppressHeaderMenuButton: true,
    flex: 1,
    minWidth: 200,
    ...(!isReadOnly
      ? {
          headerComponent: ColumnHeader,
          headerComponentParams: {
            onColumnRename: handleRenameColumn,
          } as ColumnHeaderProps,
        }
      : {}),
  });
  const handleCellChange = (e: CellValueChangedEvent<TableFieldValue.Row, any>) => {
    const cells = e.data.cells;
    const rowId = e.data.id;

    // Create a copy of the current tableData
    const updatedRows = rows.map(row => {
      if (row.id === rowId) {
        return {
          id: rowId,
          cells,
        };
      }
      return row;
    });
    const htmlValue = TableContentUtils.generateHtml({ headers, rows: updatedRows });
    send({ type: 'CHANGE', content: htmlValue });
  };

  const getContextMenuItems = React.useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      return [
        {
          name: 'Delete Row',
          disabled: rows.length === 1,
          action: () => {
            const newRows = rows.filter(row => row.id !== params.node?.data.id);
            const content = TableContentUtils.generateHtml({ headers, rows: newRows });
            setTableData({ headers, rows: newRows });
            send({ type: 'CHANGE', content });
          },
        },
      ];
    },
    [rows, headers, send, setTableData],
  );

  const getMainMenuItems = React.useCallback(
    (params: GetMainMenuItemsParams): (string | MenuItemDef)[] => {
      return [
        {
          icon: '←',
          name: 'Insert Left',
          action: params => {
            if (!params.column) {
              return;
            }
            const newData = TableContentUtils.handleInsertColumn({
              tableData: { headers, rows },
              columnId: params.column.getColId(),
              direction: 'left',
            });
            setTableData(newData);
            send({ type: 'CHANGE', content: TableContentUtils.generateHtml(newData) });
          },
        },
        {
          icon: '→',
          name: 'Insert Right',
          action: params => {
            if (!params.column) {
              return;
            }
            const newData = TableContentUtils.handleInsertColumn({
              tableData: { headers, rows },
              columnId: params.column.getColId(),
              direction: 'right',
            });
            setTableData(newData);
            send({ type: 'CHANGE', content: TableContentUtils.generateHtml(newData) });
          },
        },
        'separator',
        {
          name: 'Delete',
          disabled: params.api.getColumnDefs()?.length === 1,
          action: () => {},
        },
      ];
    },
    [send, headers, rows, setTableData],
  );

  const handleAddNewRow = React.useCallback(() => {
    const newTableData = TableContentUtils.handleAddRow(tableData);
    setTableData(newTableData);
    send({ type: 'CHANGE', content: TableContentUtils.generateHtml(newTableData) });
  }, [send, tableData, setTableData]);

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer alignItems="flex-start" fontSize="md">
        {recentlyMovedFrom && <ContentFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
        <Box
          w="full"
          sx={{
            '.ag-theme-quartz': {
              '.ag-cell-value': { color: 'gray.600' },
              '--ag-borders-critical': 'none',
              '--ag-icon-image-menu': `url('${require('app/images/data-sets/dataset-header-menu-icon.svg')}')`,
              '--ag-icon-font-display-menu': 'none',

              '--ag-header-background-color': 'var(--ps-colors-brand-50)',
              '--ag-row-hover-color': 'var(--ps-colors-brand-100)',
            },
          }}
        >
          <Box width="full" height="300" className="ag-theme-quartz">
            <TableContentContextProvider handleAddRow={handleAddNewRow} isEditable={!isReadOnly}>
              <AgGridReact
                getRowId={params => params.data.id}
                columnDefs={colDefs}
                rowData={rows}
                modules={[ClientSideRowModelModule, MenuModule]}
                getContextMenuItems={getContextMenuItems}
                getMainMenuItems={getMainMenuItems}
                overlayNoRowsTemplate="No rows to display."
                editType="fullRow"
                onRowEditingStarted={() => {
                  send({ type: 'FOCUS' });
                }}
                onRowEditingStopped={() => {
                  send({ type: 'BLUR' });
                }}
                onCellValueChanged={handleCellChange}
                suppressContextMenu={isReadOnly}
                onColumnHeaderClicked={() => send({ type: 'FOCUS' })}
                onContextMenuVisibleChanged={e => {
                  if (e.visible) {
                    send({ type: 'FOCUS' });
                  } else {
                    send({ type: 'BLUR' });
                  }
                }}
                fullWidthCellRenderer={AddNewRowRenderer}
                icons={{ menu: '⋯' }}
                isFullWidthRow={params => params.rowNode.data.isFullWidth}
                stopEditingWhenCellsLoseFocus
                suppressMovableColumns
                suppressDragLeaveHidesColumns
                suppressMenuHide
                singleClickEdit
              />
            </TableContentContextProvider>
          </Box>
        </Box>
        {!isReadOnly && (
          <>
            <WidgetListItemDragIcon />
            <FormsWidgetMenu>
              <FormsWidgetMenuItems.Duplicate />
              <FormsWidgetMenuItems.MoveToStep widget={widget} />
              <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
              <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
              <FormsWidgetMenuItems.Delete />
            </FormsWidgetMenu>
          </>
        )}
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};
