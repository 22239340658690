import { Box } from '@chakra-ui/react';
import { Muid } from '@process-street/subgrade/core';
import { CDNImage } from 'components/cdn-image';
import { useBreakpointPx } from 'components/design/next';
import * as React from 'react';
import { match, P } from 'ts-pattern';
import { TOP_BAR_HEIGHT_CSS_VAR, TOP_BAR_HEIGHT_TOKEN } from 'pages/forms/_id/shared';
import { GetBackgroundImageQuery } from 'app/features/template/query-builder';
import {
  WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT,
  WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT_CSS_VAR,
} from 'app/components/focus-bar/workflow/focus-bar-hstack';

export type BackgroundImageProps = { templateId: Muid };

export const BackgroundImage = ({ templateId }: BackgroundImageProps) => {
  const breakpointPx = useBreakpointPx();
  const coverImageQuery = GetBackgroundImageQuery.useQuery(
    { templateId },
    {
      enabled: Boolean(templateId),
      select: data => data?.s3File,
    },
  );

  return (
    <Box position="sticky" top={TOP_BAR_HEIGHT_TOKEN + WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT}>
      {match(coverImageQuery)
        .with({ status: 'success', data: P.not(P.nullish) }, ({ data }) => (
          <Box w="full" overflow="hidden" position="absolute">
            <CDNImage
              alt={data.originalName}
              s3File={data}
              transformation={{
                width: breakpointPx,
              }}
              opacity="0.7"
              h={`calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR} - ${WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT_CSS_VAR})`}
            />

            <Box position="absolute" top="0" w="full" h="full" bgGradient="linear(transparent 50%, gray.50)" />
          </Box>
        ))
        .otherwise(() => null)}
    </Box>
  );
};
