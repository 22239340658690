import { Muid } from '@process-street/subgrade/core';
import { isApproval, TaskTemplate, TaskTemplateTaskType, Widget } from '@process-street/subgrade/process';
import { ActorRefFrom, assign, createMachine, sendParent, spawn, StateFrom } from 'xstate';
import { SharedContext } from '../../shared';
import { makeTaskTemplateGenerationMachine, TaskTemplateGenerationActor } from './task-template-generation-machine';
import _keyBy from 'lodash/keyBy';
import {
  makeWidgetGenerationMachine,
  WidgetGenerationMachineActor,
} from './task-template-generation-machine/widget-generation-machine';
import { queryString } from '@process-street/subgrade/util';
import { match } from 'ts-pattern';

type TaskTemplateAndWidgetsGenerators = {
  taskTemplateGenerationActor: TaskTemplateGenerationActor;
  widgetGenerationActor: WidgetGenerationMachineActor;
};

// Record indexed by task template group, which containes actors for generating the task template and widgets, for the given task.
export type TaskTemplateGenerationActorMap = Record<Muid, TaskTemplateAndWidgetsGenerators>;

type Context = {
  sharedContext: SharedContext;
  taskTemplateGenerationActorMap: TaskTemplateGenerationActorMap;
  taskTemplatesToAnimate: TaskTemplate[]; // list of task templates to animate, since we want to animate one after the other
  allTaskTemplates: TaskTemplate[];
};

export type AiWorkflowGenerationEvent =
  | { type: 'ANIMATE_TASK_TEMPLATE'; taskTemplate: TaskTemplate }
  | { type: 'ANIMATE_TASK_TEMPLATE_WIDGETS'; taskTemplate: TaskTemplate }
  | { type: 'UPDATE_WIDGET'; taskTemplateGroupId: Muid; widget: Widget }
  | { type: 'UPDATE_TASK_TEMPLATE'; taskTemplate: TaskTemplate }
  | { type: 'TASK_TEMPLATE_ANIMATION_FINISH'; taskTemplate: TaskTemplate }
  | { type: 'WIDGETS_ANIMATION_FINISH'; taskTemplate: TaskTemplate };

export type AiWorkflowGenerationMachine = ReturnType<typeof makeAiWorkflowGenerationMachine>;
export type AiWorkflowGenerationMachineActorRef = ActorRefFrom<AiWorkflowGenerationMachine>;

export const makeAiWorkflowGenerationMachine = ({ sharedContext }: { sharedContext: SharedContext }) => {
  return createMachine(
    {
      id: `ai-workflow-generation-machine`,
      initial: 'idle',
      predictableActionArguments: true,
      schema: {
        events: {} as AiWorkflowGenerationEvent,
        context: {} as Context,
      },
      tsTypes: {} as import('./ai-workflow-generation-machine.typegen').Typegen0,
      context: () =>
        ({
          sharedContext,
          taskTemplateGenerationActorMap: {},
          taskTemplatesToAnimate: [],
          allTaskTemplates: [],
        } as Context),
      states: {
        idle: {
          on: {
            ANIMATE_TASK_TEMPLATE: [
              {
                target: 'animating',
                actions: ['assignTaskTemplatesToAnimate', 'animateTaskTemplate'],
              },
            ],
            ANIMATE_TASK_TEMPLATE_WIDGETS: [
              {
                target: 'animating',
                actions: 'animateTaskTemplateWidgets',
              },
            ],
          },
        },
        animating: {
          on: {
            UPDATE_WIDGET: { actions: 'sendWidgetUpdated' },
            UPDATE_TASK_TEMPLATE: { actions: 'sendTaskTemplateUpdated' },
            ANIMATE_TASK_TEMPLATE: [
              {
                cond: 'areNotPendingTasksToAnimate',
                actions: ['animateTaskTemplate'],
              },
              { cond: 'arePendingTasksToAnimate', actions: 'assignTaskTemplatesToAnimate' },
            ],
            ANIMATE_TASK_TEMPLATE_WIDGETS: { actions: 'animateTaskTemplateWidgets' },
            // when a task finished animating, check if we have a pending task animation, and if so, fire it.
            WIDGETS_ANIMATION_FINISH: [
              {
                target: 'done',
                cond: 'isEveryTaskTemplateWidgetAnimated',
              },
              { actions: 'goToNextActiveWidgetAnimation', cond: 'isSomeTaskTemplateWidgetAnimating' },
            ],
            TASK_TEMPLATE_ANIMATION_FINISH: { actions: 'checkAndFirePendingAnimations' },
          },
        },
        done: {
          type: 'final',
        },
      },
    },
    {
      actions: {
        goToNextActiveWidgetAnimation: ctx => {
          // Find the next task template where their widgets are still being animating
          const nextTaskToAnimatingWidgets = ctx.allTaskTemplates.find(tt => {
            return (
              ctx.taskTemplateGenerationActorMap[tt.group.id]?.widgetGenerationActor?.getSnapshot()?.value ===
              'animating'
            );
          });

          if (nextTaskToAnimatingWidgets) {
            sharedContext.navigate({
              pathname: 'templateV2.task',
              search: queryString.stringify({
                groupId: nextTaskToAnimatingWidgets.group.id,
                id: sharedContext.templateId,
              }),
            });
          }
        },
        checkAndFirePendingAnimations: assign((ctx, evt) => {
          const nextTaskToAnimate = ctx.taskTemplatesToAnimate.filter(t => t.id !== evt.taskTemplate.id)?.[0];

          if (nextTaskToAnimate) {
            // theres a task queued to animate, fire it
            const taskTemplateActor =
              ctx.taskTemplateGenerationActorMap[nextTaskToAnimate.group.id]?.taskTemplateGenerationActor ??
              spawn(makeTaskTemplateGenerationMachine({ taskTemplate: nextTaskToAnimate, sharedContext }));

            taskTemplateActor.send({ type: 'ANIMATE' });

            return {
              taskTemplateGenerationActorMap: {
                ...ctx.taskTemplateGenerationActorMap,
                [evt.taskTemplate.group.id]: {
                  ...ctx.taskTemplateGenerationActorMap[nextTaskToAnimate.group.id],
                  taskTemplateGenerationActor: taskTemplateActor,
                },
              },
              taskTemplatesToAnimate: ctx.taskTemplatesToAnimate.filter(t => t.id !== evt.taskTemplate.id),
            };
          } else {
            // no tasks queued to animate
            return ctx;
          }
        }),
        assignTaskTemplatesToAnimate: assign((ctx, evt) => {
          return {
            taskTemplatesToAnimate: [...ctx.taskTemplatesToAnimate, evt.taskTemplate],
            allTaskTemplates: [...ctx.allTaskTemplates, evt.taskTemplate],
          };
        }),
        animateTaskTemplate: assign((ctx, evt) => {
          const actor = ctx.taskTemplateGenerationActorMap[evt.taskTemplate.group.id]?.taskTemplateGenerationActor;

          if (actor) {
            actor.send({ type: 'ANIMATE' });
            return ctx;
          } else {
            const taskTemplateActor = spawn(
              makeTaskTemplateGenerationMachine({ taskTemplate: evt.taskTemplate, sharedContext }),
            );
            const prev = ctx.taskTemplateGenerationActorMap[evt.taskTemplate.group.id];
            taskTemplateActor.send({ type: 'ANIMATE' });
            return {
              taskTemplateGenerationActorMap: {
                ...ctx.taskTemplateGenerationActorMap,
                [evt.taskTemplate.group.id]: {
                  ...prev,
                  taskTemplateGenerationActor: taskTemplateActor,
                },
              },
            };
          }
        }),
        animateTaskTemplateWidgets: assign((ctx, evt) => {
          const actor = ctx.taskTemplateGenerationActorMap[evt.taskTemplate.group.id]?.widgetGenerationActor;

          const noTaskTemplateWidgetsAnimating = Object.values(ctx.taskTemplateGenerationActorMap).every(item => {
            return !item.widgetGenerationActor || item.widgetGenerationActor?.getSnapshot()?.value === 'done';
          });

          if (noTaskTemplateWidgetsAnimating) {
            sharedContext.navigate({
              pathname: 'templateV2.task',
              search: queryString.stringify({
                groupId: evt.taskTemplate.group.id,
                id: sharedContext.templateId,
              }),
            });
          }

          if (actor) {
            actor.send({ type: 'ANIMATE' });
            return ctx;
          } else {
            const taskTemplateWidgetsActor = spawn(
              makeWidgetGenerationMachine({ taskTemplate: evt.taskTemplate, sharedContext }),
            );
            const prev = ctx.taskTemplateGenerationActorMap[evt.taskTemplate.group.id];
            taskTemplateWidgetsActor.send({ type: 'ANIMATE' });
            return {
              taskTemplateGenerationActorMap: {
                ...ctx.taskTemplateGenerationActorMap,
                [evt.taskTemplate.group.id]: {
                  ...prev,
                  widgetGenerationActor: taskTemplateWidgetsActor,
                },
              },
            };
          }
        }),
        sendWidgetUpdated: sendParent((_ctx, evt) => ({ type: 'UPDATE_GENERATED_WIDGET', widget: evt.widget })),
        sendTaskTemplateUpdated: sendParent((_ctx, evt) => ({
          type: 'UPDATE_GENERATED_TASK_TEMPLATE',
          taskTemplate: evt.taskTemplate,
        })),
      },
      guards: {
        arePendingTasksToAnimate: (ctx, _evt) => ctx.taskTemplatesToAnimate.length > 0,
        areNotPendingTasksToAnimate: (ctx, _evt) => ctx.taskTemplatesToAnimate.length === 0,
        isSomeTaskTemplateWidgetAnimating: ctx =>
          Object.values(ctx.taskTemplateGenerationActorMap).some(item => {
            return item.widgetGenerationActor?.getSnapshot()?.value === 'animating';
          }),
        isEveryTaskTemplateWidgetAnimated: ctx => {
          const approvalTasksCount = ctx.allTaskTemplates.filter(
            tt => tt.taskType === TaskTemplateTaskType.Approval,
          ).length;
          const completedTasksCount = Object.values(ctx.taskTemplateGenerationActorMap).filter(item => {
            return item.widgetGenerationActor?.getSnapshot()?.value === 'done';
          }).length;

          return completedTasksCount + approvalTasksCount === ctx.allTaskTemplates.length;
        },
      },
    },
  );
};

export const AiWorkflowGenerationActorSelector = {
  isGenerating(state: StateFrom<AiWorkflowGenerationMachine>) {
    return (
      state.matches('animating') ||
      Object.values(state.context.taskTemplateGenerationActorMap).some(
        actor =>
          actor.taskTemplateGenerationActor?.getSnapshot()?.matches('animating') ||
          actor.widgetGenerationActor?.getSnapshot()?.matches('animating'),
      )
    );
  },

  isDone(state: StateFrom<AiWorkflowGenerationMachine>) {
    return state.matches('done');
  },

  isTaskTemplateAnimationDone: (taskTemplateGroupId: Muid) => (state: StateFrom<AiWorkflowGenerationMachine>) => {
    return (
      state.context.taskTemplateGenerationActorMap[taskTemplateGroupId]?.widgetGenerationActor
        ?.getSnapshot()
        ?.matches('done') ?? false
    );
  },

  getTaskTemplateStatusMap(state: StateFrom<AiWorkflowGenerationMachine>) {
    const entries = Object.entries(state.context.taskTemplateGenerationActorMap)
      .map(([_, actor]) => {
        const state = actor.widgetGenerationActor?.getSnapshot();
        const taskTemplate = state?.context.taskTemplate;
        const status = match({ taskType: taskTemplate?.taskType, state: state?.value })
          .with({ taskType: TaskTemplateTaskType.Approval }, () => 'ignored')
          .with({ state: 'done' }, () => 'loaded')
          .otherwise(() => 'loading');

        return [taskTemplate?.id, status];
      })
      .filter(([id]) => {
        return typeof id === 'string';
      });

    return Object.fromEntries(entries);
  },

  getIsTaskAnimatingWidgetsMap(state: StateFrom<AiWorkflowGenerationMachine>) {
    return _keyBy(
      Object.entries(state.context.taskTemplateGenerationActorMap).map(([taskTemplateGroupId, actor]) => ({
        taskTemplateGroupId,
        isAnimating: actor.widgetGenerationActor?.getSnapshot()?.matches('animating') || false,
      })),
      'taskTemplateGroupId',
    );
  },

  getFirstGeneratingTaskTemplate(state: StateFrom<AiWorkflowGenerationMachine>) {
    const animatingActorEntry = Object.entries(state.context.taskTemplateGenerationActorMap).find(
      ([_, actor]) =>
        (!isApproval(actor.taskTemplateGenerationActor?.getSnapshot()?.context.taskTemplate) &&
          actor.taskTemplateGenerationActor?.getSnapshot()?.matches('animating')) ||
        (!isApproval(actor.widgetGenerationActor?.getSnapshot()?.context.taskTemplate) &&
          actor.widgetGenerationActor?.getSnapshot()?.matches('animating')),
    );
    if (animatingActorEntry) {
      const taskTemplateActor = animatingActorEntry[1].taskTemplateGenerationActor;
      return taskTemplateActor ? taskTemplateActor.state.context.taskTemplate : undefined;
    }
    return undefined;
  },
};
