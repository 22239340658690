import * as React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  VStack,
  Text,
} from 'components/design/next';
import { useTaskTemplateListActorRef } from '../../../../hooks/use-task-templates-list-actor';
import { useSelector } from '@xstate/react';
import { TaskTemplateListActorSelectors } from '../../task-template-list-machine';
import pluralize from 'pluralize';
import { WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT } from 'app/components/focus-bar/workflow/focus-bar-hstack';
import { TOP_BAR_HEIGHT_CSS_VAR, useSharedContext } from 'app/pages/forms/_id/shared';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { useWorkflowState } from 'app/components/focus-bar/workflow/use-workflow-state';
import _isEqual from 'lodash/isEqual';
import { useGetConsolidatedTemplatePermissionsQuery } from 'app/features/permissions/query-builder';
import { Template, TemplateRevision, TemplateRevisionStatus, TemplateStatus } from '@process-street/subgrade/process';
import { useCurrentUser } from 'app/hooks/use-current-user';
import { useSelectedOrganization } from 'app/hooks/use-selected-organization';
import { canAccess, Feature } from 'app/services/features/features';
import { BulkAssignTaskButton } from './bulk-assign-task';
import { BulkStopTaskButton } from './bulk-stop-task';
import { TaskPermissionsPicker } from 'app/components/task-permission/components/task-permissions-picker';
import { BulkMoveTaskButton } from './bulk-move-task';
import { BulkDeleteTaskButton } from './bulk-delete-task';
import { TaskActionsDueDatePicker } from '../../../task-actions-bar/task-actions-due-date-picker-button';

const BULK_ACTIONS_DRAWER_WIDTH_PX = 211;
const TASK_TEMPLATES_DRAWER_WIDTH_PX = 480;
const FOCUS_BAR_BORDER_PX = 2;

const DRAWER_TOP = `calc(${TOP_BAR_HEIGHT_CSS_VAR} + var(--ps-sizes-${WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT}) - ${FOCUS_BAR_BORDER_PX}px) !important`;
const DRAWER_LEFT = `${BULK_ACTIONS_DRAWER_WIDTH_PX + TASK_TEMPLATES_DRAWER_WIDTH_PX}px`;

export type BulkActionsDrawerProps = {
  template: Template;
  templateRevision: TemplateRevision;
};

export const BulkActionsDrawer = ({ templateRevision, template }: BulkActionsDrawerProps) => {
  const actor = useTaskTemplateListActorRef();
  const { queryClient } = useSharedContext();
  const selectedTaskTemplatesIds = useSelector(actor, TaskTemplateListActorSelectors.getSelectedTaskTemplatesIds);
  const selectedTaskTemplates = useSelector(actor, TaskTemplateListActorSelectors.getSelectedTaskTemplates, _isEqual);
  const isEditable = useWorkflowState() === 'edit';
  const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(template.id);

  const currentUser = useCurrentUser();
  const selectedOrganization = useSelectedOrganization();
  const planId = selectedOrganization?.subscription.plan.id;
  const canAccessDynamicDueDates = currentUser && planId && canAccess(Feature.DYNAMIC_DUE_DATES, planId);

  const canManageTasks =
    templateRevision?.status === TemplateRevisionStatus.Draft &&
    template?.status === TemplateStatus.Active &&
    permissionsQuery.data?.permissionMap.templateUpdate;

  const handleClose = () => {
    actor.send({ type: 'CANCEL' });
  };

  return (
    <Drawer
      variant="aside"
      trapFocus={false}
      blockScrollOnMount={false}
      isOpen={selectedTaskTemplatesIds.length > 1}
      onClose={handleClose}
      placement="left"
    >
      <DrawerOverlay
        motionProps={{
          animate: false,
        }}
        zIndex={2}
        top={DRAWER_TOP}
        left={DRAWER_LEFT}
        onClick={handleClose}
      />
      <DrawerContent
        motionProps={{ animate: false }}
        maxW={`${BULK_ACTIONS_DRAWER_WIDTH_PX}px`}
        h={`calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR} + var(--ps-sizes-${WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT}))`}
        top={DRAWER_TOP}
        ml={DRAWER_LEFT}
        bgColor="brand.50"
        boxShadow="0px 4px 6px -1px #0000001A;"
      >
        <ThemeProvider2024>
          <DrawerCloseButton size="md" color="gray.500" right={1} />
          <DrawerHeader>
            <Text variant="-1u" fontSize="md" color="gray.500">
              {selectedTaskTemplatesIds.length} {pluralize('task', selectedTaskTemplatesIds.length)} selected
            </Text>
          </DrawerHeader>
          <DrawerBody>
            <VStack spacing="3">
              <BulkAssignTaskButton
                taskTemplates={selectedTaskTemplates}
                isEditable={isEditable}
                isDisabled={!canManageTasks}
              />
              {canAccessDynamicDueDates && (
                <TaskActionsDueDatePicker
                  selectedTaskTemplate={undefined}
                  selectedTaskTemplates={selectedTaskTemplates}
                  editable={isEditable}
                  templateRevision={templateRevision}
                  disabled={!canManageTasks}
                  queryClient={queryClient}
                />
              )}
              <TaskPermissionsPicker
                taskTemplates={selectedTaskTemplates}
                templateRevisionId={templateRevision.id}
                isDisabled={!canManageTasks}
              />
              <BulkStopTaskButton />
              <BulkDeleteTaskButton />
              <BulkMoveTaskButton direction="up" />
              <BulkMoveTaskButton direction="down" />
            </VStack>
          </DrawerBody>
        </ThemeProvider2024>
      </DrawerContent>
    </Drawer>
  );
};
