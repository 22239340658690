import { Folder, Template } from '@process-street/subgrade/process';
import {
  PropsOf,
  BreadcrumbLink,
  Breadcrumb,
  BreadcrumbItem,
  Skeleton,
  BreadcrumbSeparator,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  IconButton,
  ThemeTypings,
} from 'components/design/next';
import * as React from 'react';
import { getBreadcrumbPaths, isPrivateRootFolder, isOrganizationRootFolder } from 'features/folder/lib';
import { useReadableFoldersQuery, useWriteableFoldersQuery } from 'features/folder/query-builder';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useFeatureFlag } from 'features/feature-flags';
import { FolderRowDragZoneWrapper } from 'pages/library/components/template-library/folder-row/dropzone-wrapper';
import { useGetAllConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';

const PSBreadcrumbLink: React.FC<React.PropsWithChildren<PropsOf<typeof BreadcrumbLink>>> = props => {
  return (
    <BreadcrumbLink
      borderRadius="sm"
      fontSize="md"
      wordBreak="break-all"
      noOfLines={1}
      _hover={{
        bgColor: 'transparent',
        textDecoration: 'none',
        backgroundColor: 'gray.100',
      }}
      {...props}
    />
  );
};

const useBreadcrumbs = ({ templateId, folderId }: { templateId?: Template['id']; folderId?: Folder['id'] }) => {
  const selectedOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);

  const { data: parentFolderId } = useGetTemplateQuery(
    { templateId: templateId! },
    {
      enabled: Boolean(templateId) && !folderId,
      select: template => template.folder.id,
    },
  );

  const folderIdToFetch = folderId ?? parentFolderId;

  return useReadableFoldersQuery(selectedOrganizationId!, {
    enabled: Boolean(selectedOrganizationId) && Boolean(folderIdToFetch),
    select: getBreadcrumbPaths(folderIdToFetch!),
  });
};

const getFolderLabel = (folder: Folder) => {
  if (isPrivateRootFolder(folder)) {
    return 'Private Library';
  }
  if (isOrganizationRootFolder(folder)) {
    return 'Library';
  }
  return folder.name;
};

const getFolderIcon = (folder: Folder): IconName => {
  return isPrivateRootFolder(folder) || isOrganizationRootFolder(folder) ? 'home' : 'folder-open';
};

export type Props = {
  getColor?: (options: { light: ThemeTypings['colors']; dark: ThemeTypings['colors'] }) => string;
  templateId?: Template['id'];
  folderId?: Folder['id'];
  enableDnd?: boolean;
};
const BreadcrumbsImpl: React.FC<React.PropsWithChildren<Props>> = ({
  templateId,
  folderId,
  getColor = ({ light }) => light,
  enableDnd,
}) => {
  const { data: folders, isLoading } = useBreadcrumbs({ templateId, folderId });
  // just some fun wordplay here o_O
  const [ultimate, penultimate, antepenultimate, ...rest] = folders?.reverse() ?? [];
  // if there are more than 3 folders, we collapse the folders leading up to the last three
  const shouldCollapseExcess = rest.length > 0;
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId);

  const isDragAndDropEnabled = useFeatureFlag('dragAndDropLibrary');

  const writeableFoldersQuery = useWriteableFoldersQuery(organizationId ?? '', {
    enabled: isDragAndDropEnabled,
  });

  const { data: templatePermissionsById } = useGetAllConsolidatedTemplatePermissionsQuery({
    enabled: isDragAndDropEnabled,
    select: allTemplatePermits =>
      Object.fromEntries(allTemplatePermits.map(permission => [permission.templateId, permission])),
  });

  const writeableFoldersSet = React.useMemo(() => {
    return new Set(writeableFoldersQuery.data?.map(folder => folder.id) ?? []);
  }, [writeableFoldersQuery.data]);

  const withDropzone = (children: React.ReactElement, folder: Folder) => {
    if (!isDragAndDropEnabled || !enableDnd) return children;

    return (
      <FolderRowDragZoneWrapper
        key={folder.id}
        showTooltip
        folder={folder}
        writeableFoldersSet={writeableFoldersSet}
        templatePermissionsById={templatePermissionsById}
      >
        {children}
      </FolderRowDragZoneWrapper>
    );
  };
  return (
    <Skeleton
      spacing="2.5"
      sx={{ '& > ol': { display: 'flex', px: '0', mb: 0 } }}
      separator={<BreadcrumbSeparator color={getColor({ light: 'gray.300', dark: 'gray.500' })}>/</BreadcrumbSeparator>}
      as={Breadcrumb}
      isLoaded={!isLoading && !!folders}
    >
      {shouldCollapseExcess ? (
        <BreadcrumbItem key="folder-tree">
          <Menu autoSelect={false}>
            <MenuButton
              as={IconButton}
              _hover={{
                'textDecoration': 'none',
                'backgroundColor': 'gray.100',
                '& svg': {
                  color: 'gray.600',
                },
              }}
              _active={{
                'textDecoration': 'none',
                'backgroundColor': 'gray.100',
                '& svg': {
                  color: 'gray.600',
                },
              }}
              icon={
                <Icon
                  icon="folder-tree"
                  variant="far"
                  size="4"
                  color={getColor({ light: 'gray.500', dark: 'gray.200' })}
                />
              }
              variant="ghost"
              size="xs"
            />

            <MenuList>
              {[antepenultimate, ...rest].map(folder => {
                return (
                  <MenuItem
                    as={BreadcrumbLink}
                    key={folder.id}
                    icon={<Icon icon={getFolderIcon(folder)} size="4" variant="far" color="gray.500" />}
                    href={`/library/folders/${folder.path}`}
                    color="gray.500"
                    _hover={{ bgColor: 'transparent' }}
                  >
                    {getFolderLabel(folder)}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        </BreadcrumbItem>
      ) : // if there is not excess but still a third, render it
      antepenultimate ? (
        <BreadcrumbItem key={antepenultimate.id}>
          {withDropzone(
            <PSBreadcrumbLink
              fontSize="md"
              href={`/library/folders/${antepenultimate.path}`}
              color={getColor({ light: 'gray.500', dark: 'gray.200' })}
            >
              {getFolderLabel(antepenultimate)}
            </PSBreadcrumbLink>,
            antepenultimate,
          )}
        </BreadcrumbItem>
      ) : null}

      {/* always render the last two folders (penultimate could be null)  */}
      {[penultimate, ultimate].map(folder => {
        return folder ? (
          <BreadcrumbItem key={folder.id}>
            {withDropzone(
              <PSBreadcrumbLink
                href={`/library/folders/${folder.path}`}
                color={getColor({ light: 'gray.500', dark: 'gray.200' })}
              >
                {getFolderLabel(folder)}
              </PSBreadcrumbLink>,
              folder,
            )}
          </BreadcrumbItem>
        ) : null;
      })}
    </Skeleton>
  );
};

export const Breadcrumbs = React.memo(BreadcrumbsImpl);
