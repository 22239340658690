import TurndownService from 'turndown';
import MarkdownIt from 'markdown-it';
import emoji from 'markdown-it-emoji';
import mila from 'markdown-it-link-attributes';

const turndownService = new TurndownService();
turndownService.addRule('strikethrough', {
  filter: ['del', 's'],
  replacement: content => '~~' + content + '~~',
});

const markdownParser = new MarkdownIt({
  html: false,
  breaks: true,
  linkify: true,
  xhtmlOut: true,
})
  .use(emoji)
  .use(mila, {
    attrs: {
      target: '_blank',
    },
  });

export const TextAreaRichEditorHelper = {
  html2markdown: (content: string) => turndownService.turndown(content),
  markdown2html: (md: string) => markdownParser.renderInline(md),
};
